<template>
    <MbscSnackbar display="top"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />

    <div class="login">

        <div class="pre-loader" v-if="isLoading"></div>

        <div class="login-container">

            <div class="login-box">
                <img src="@/assets/images/logo-white.svg" alt="MapAuthority" class="logo" />

                <form class="login-form" @submit.prevent="onFormSubmit">
                    <h4 class="text-center mb-4">Sign In</h4>
                    <div class="row align-items-center mb-3">
                        <div class="col-auto login-icon"><i class="fal fa-user fa-2x"></i></div>
                        <div class="col">
                            <input type="email"
                                   class="form-control form-control-lg dark"
                                   placeholder="Email Address"
                                   v-model="email" />
                        </div>
                    </div>
                    <div class="row align-items-center mb-5">
                        <div class="col-auto login-icon"><i class="fal fa-lock fa-2x"></i></div>
                        <div class="col">
                            <input type="password"
                                   class="form-control form-control-lg dark"
                                   placeholder="Password"
                                   v-model="password" />
                        </div>
                    </div>
                    <div class="d-grid mb-5">
                        <button type="submit"
                                class="btn btn-primary btn-lg mx-auto btn-round px-5 hvr-grow">
                            Sign In
                        </button>
                    </div>
                    <div class="text-center">
                        <router-link class="forgot-password-link" :to="{ name: 'forgot-password', query: { email: email } }">Forgot Password?</router-link>
                    </div>
                </form>
            </div>

        </div>

        <LoginFooter />

    </div>
</template>

<script lang="ts" setup>
    import axios from 'axios'
    import { MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import LoginFooter from '@/components/LoginFooter.vue'
    import { useCookie } from 'vue-cookie-next'
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'

    const router = useRouter()
    const cookies = useCookie()

    const email = ref('')
    const isLoading = ref(true)
    const isSnackbarOpen = ref(false)
    const snackbarMessage = ref('')
    const password = ref('')

    onMounted(() => {
        isLoading.value = false
    })

    const onFormSubmit = (async () => {
        try {

            isLoading.value = true
            let roles:Array<string> = (await axios.post('/api/user/login', {
                email: email.value,
                password: password.value
            })).data

            for (const role of roles) {
                cookies.setCookie(`role:${role}`, role)
            }

            if (cookies.getCookie('role:SuperAdmin') == 'SuperAdmin') {
                router.push({ name: 'admin-users' })
            } else {
                router.push({ name: 'products-dashboard-products-view' })
            }
        } catch (error) {
            showSnackbar('Invalid username or password.')
        } finally {
            isLoading.value = false
        }
    })

    const showSnackbar = ((msg: string) => {
        snackbarMessage.value = msg
        isSnackbarOpen.value = true
    })

</script>