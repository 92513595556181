<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />

    <div class="pre-loader" v-if="isLoading"></div>

    <div class="site-container">
        <UserMenu />
        <div class="admin-page-content">
            <ImpersonateBanner />
            <div class="container">
                <UserProfileDropdown />
                <div class="row align-items-center mb-5">
                    <div class="col-md-auto mb-3 mb-md-0">
                        <h2 class="m-0 text-strong"><i class="fal fa-shop"></i> Retailers</h2>
                    </div>
                    <div class="col-md-auto">
                        <button type="button"
                                id="btn-add-new-retailer"
                                data-bs-toggle="modal"
                                data-bs-target="#new-retailer"
                                class="btn btn-sm px-3 btn-primary btn-round hvr-grow mobile-100"
                                @click="onAddRetailerClicked">
                            <i class="fal fa-plus"></i> New
                        </button>
                    </div>
                </div>
                <div class="input-group input-group-lg mb-3">
                    <input type="text" class="form-control"
                           placeholder="Search retailers..."
                           aria-label="Search retailers..."
                           v-model="searchValue"
                           v-on:keyup.enter="reloadTable()">
                    <button class="btn btn-white"
                            type="button"
                            @click="reloadTable()">
                        <i class="fal fa-search"></i>
                    </button>
                </div>
                <div class="row mb-4">
                    <div class="col-xxl-2 col-xl-5">
                        <div class="dropdown w-100">
                            <button class="btn btn-white btn-sm dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{ selectedStatus }}</span>
                            </button>
                            <ul id="status-dropdown"
                                class="dropdown-menu w-100" 
                                aria-labelledby="dropdownMenuButton1">
                                <li v-for="option in statusOptions" :key="option">
                                    <a class="dropdown-item status-dropdown-item"
                                       :class="{'active': selectedStatus == option }"
                                       href="#"
                                       @click.prevent="onStatusChanged(option)">{{ option }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <DataTable :columns="tableColumns"
                               :options="tableOptions"
                               class="table table-hover align-middle m-0"
                               width="100%"
                               ref="table">
                        <thead>
                            <tr>
                                <th>Retailer</th>
                                <th>Primary Contact</th>
                                <th>Seller Id</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                    </DataTable>
                </div>
            </div>
        </div>
        <UserFooter />
    </div>

    <!-- New/Edit Retailer Modal -->
    <div class="modal fade" id="new-retailer" tabindex="-1" aria-labelledby="new-retailer" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">{{isEditing ? 'Edit' : 'Add'}} Retailer</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-9 mb-3">
                            <label for="retailer-name" class="form-label">Retailer Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="retailer-name"
                                   placeholder="Retailer Name"
                                   v-model="addOrEditRetailer.name"
                                   v-bind:class="{'is-invalid' : !validRetailerName() && retailerNameBlured}"
                                   v-on:blur="retailerNameBlured = true"
                                   required>
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="is-active" class="form-label d-block">Status</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input"
                                       type="checkbox"
                                       role="switch"
                                       id="is-active"
                                       v-model="addOrEditRetailer.isActive"
                                       checked>
                                <label class="form-check-label" for="is-active">Active</label>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <h5 class="text-strong mb-4">Primary Contact</h5>
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="retailer-first-name" class="form-label">First Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="retailer-first-name"
                                   placeholder="First Name"
                                   v-model="addOrEditRetailer.firstName"
                                   v-bind:class="{'is-invalid' : !validRetailerFirstName() && retailerFirstNameBlured}"
                                   v-on:blur="retailerFirstNameBlured = true"
                                   required>
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="last-name" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="retailer-last-name"
                                   placeholder="Last Name"
                                   v-model="addOrEditRetailer.lastName"
                                   v-bind:class="{'is-invalid' : !validRetailerLastName() && retailerLastNameBlured}"
                                   v-on:blur="retailerLastNameBlured = true"
                                   required>
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="email" class="form-label">Email Address<sup class="text-danger">*</sup></label>
                            <input type="email"
                                   class="form-control"
                                   id="retailer-email"
                                   placeholder="Email Address"
                                   v-model="addOrEditRetailer.email"
                                   v-bind:class="{'is-invalid' : !validRetailerEmail() && retailerEmailBlured}"
                                   v-on:blur="retailerEmailBlured = true"
                                   required>
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must contain a valid email.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="phone" class="form-label">Phone<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="retailer-phone"
                                   placeholder="Phone"
                                   v-model="addOrEditRetailer.phone"
                                   v-bind:class="{'is-invalid' : !validRetailerPhone() && retailerPhoneBlured}"
                                   v-on:blur="retailerPhoneBlured = true"
                                   required>
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must contain a 10 digit phone number.
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-outline-secondary btn-round me-2"
                            data-bs-dismiss="modal"
                            ref="closeModalButton">
                        Cancel
                    </button>
                    <button type="button"
                            id="btn-submit"
                            class="btn btn-secondary btn-round"
                            @click="onSubmitClicked()">
                        Save Retailer
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts" setup>
    import axios, { AxiosError } from 'axios'
    import { CustomRegex } from '@/assets/CustomRegex'
    import { MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { CompanyDto } from '@/assets/types'
    import ImpersonateBanner from '@/components/ImpersonateBanner.vue'
    import UserFooter from '@/components/UserFooter.vue'
    import UserMenu from '@/components/UserMenu.vue'
    import UserProfileDropdown from '@/components/UserProfileDropdown.vue'
    import DataTable from 'datatables.net-vue3'
    import DataTablesCore from 'datatables.net-bs5'
    import { onMounted, ref } from 'vue'

    DataTable.use(DataTablesCore)

    const emptyRetailer: Readonly<CompanyDto> = {
        id: null,
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        imageUrl: '',
        currentAsinCount: null,
        maxAllowedAsins: null,
        isAllowedToLWA: null,
        sellingPartnerId: '',
        priceCheckFrequency: null,
        isActive: true
    }

    const addOrEditRetailer = ref<CompanyDto>(JSON.parse(JSON.stringify(emptyRetailer)))
    const closeModalButton = ref<HTMLButtonElement>()
    const isEditing = ref(false)
    const isLoading = ref(false)
    const isSnackbarOpen = ref(false)
    const isTestMode = ref(false)
    const retailerNameBlured = ref(false)
    const retailerFirstNameBlured = ref(false)
    const retailerLastNameBlured = ref(false)
    const retailerEmailBlured = ref(false)
    const retailerPhoneBlured = ref(false)
    const searchValue = ref('')
    const selectedStatus = ref('Active')
    const snackbarMessage = ref('')
    const statusOptions = ref(['All', 'Active', 'Deactivated'])
    const table = ref<typeof DataTable>(DataTable)

    let dt: any
    onMounted(() => {
        dt = table.value.dt
        dt.on('click', '#btn-edit-retailer', function (e: any) {
            let retailerRowJson = dt.row(e.target.closest('tr')).data()
            onEditRetailerClicked(retailerRowJson)
        })
        dt.on('click', '#btn-resend-link-account-email', function (e: any) {
            let retailerRowJson = dt.row(e.target.closest('tr')).data()
            onResendLinkAccountEmailClicked(retailerRowJson)
        })
    })

    const onAddRetailerClicked = (() => {
        addOrEditRetailer.value = JSON.parse(JSON.stringify(emptyRetailer))
        isEditing.value = false
    })

    const onEditRetailerClicked = ((retailerRowJson: any) => {
        addOrEditRetailer.value = JSON.parse(JSON.stringify(retailerRowJson))
        isEditing.value = true
    })

    const onResendLinkAccountEmailClicked = (async (retailerRowJson: any) => {
        addOrEditRetailer.value = JSON.parse(JSON.stringify(retailerRowJson))
        try {
            await axios.post('/api/company/retailer/resend-welcome-email', addOrEditRetailer.value)
            showSnackbar('Retailer welcome email was resent.')
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onStatusChanged = ((option) => {
        selectedStatus.value = option
        reloadTable()
    })

    const onSubmitClicked = (async () => {
        try {
            if (!validate()) {
                return
            }
            addOrEditRetailer.value.phone = addOrEditRetailer.value.phone.replace(/\D/g, '')
            if (isEditing.value) {
                await axios.put('/api/company/retailer', addOrEditRetailer.value)
                showSnackbar('Retailer was updated.')
            } else {
                await axios.post('/api/company/retailer', addOrEditRetailer.value)
                showSnackbar('Retailer was created.')
            }
            closeModalButton.value?.click()
            reloadTable()
            resetValidation()
        } catch (error) {
            const axiosError = error as AxiosError
            if (axiosError.response?.status == 400) {
                showSnackbar('' + axiosError.response?.data)
            } else {
                showSnackbar('An error occurred. Please contact support.')
            }
        }
    })

    const validate = (() => {
        retailerNameBlured.value = true
        retailerFirstNameBlured.value = true
        retailerLastNameBlured.value = true
        retailerEmailBlured.value = true
        retailerPhoneBlured.value = true
        return validRetailerName() && validRetailerFirstName() && validRetailerLastName() && validRetailerEmail() && validRetailerPhone()
    })

    const validRetailerName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(addOrEditRetailer.value.name.toLowerCase())) {
            return true
        }
    })

    const validRetailerFirstName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(addOrEditRetailer.value.firstName.toLowerCase())) {
            return true
        }
    })

    const validRetailerLastName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(addOrEditRetailer.value.lastName.toLowerCase())) {
            return true
        }
    })

    const validRetailerEmail = (() => {
        let regex = CustomRegex.isEmailAddress
        if (addOrEditRetailer.value.email.trim() == '' || regex.test(addOrEditRetailer.value.email.toLowerCase())) {
            return true
        }
    })

    const validRetailerPhone = (() => {
        let regex = CustomRegex.isPhoneNumber
        if (addOrEditRetailer.value.phone.trim() == '' || regex.test(addOrEditRetailer.value.phone.toLowerCase())) {
            return true
        }
    })

    const resetValidation = (() => {
        addOrEditRetailer.value = JSON.parse(JSON.stringify(emptyRetailer))
        retailerNameBlured.value = false
        retailerFirstNameBlured.value = false
        retailerLastNameBlured.value = false
        retailerEmailBlured.value = false
        retailerPhoneBlured.value = false
    })

    const reloadTable = (() => {
        table.value.dt.ajax.reload()
    })

    const showSnackbar = ((msg: string) => {
        snackbarMessage.value = msg
        isSnackbarOpen.value = true
    })

    const tableColumns = ref([
        {
            data: 'name'
        },
        {
            data: 'primary-contact',
            render: function (data: any, type: any, row: any) {
                return `${row.firstName} ${row.lastName}
                <ul class="d-flex m-0 list-unstyled small">
                    <li class="me-3"><i class="far fa-envelope opacity-50"></i> <a href="mailto:${row.email}" class="text-body">${row.email}</a></li>
                    <li><i class="far fa-phone opacity-50"></i> ${formatPhone(row.phone)}</li>
                </ul>`
            }
        },
        {
            data: 'sellingPartnerId',
            render: function (data: any) {
                return data == '' ? '<em">Awaiting LWA</em>' : data
            }
        },
        {
            data: 'isActive',
            render: function (data: any) {
                return data ? 'Active' : 'Deactivated'
            }
        },
        {
            className: 'action-column',
            data: null,
            render: function (data: any, type: any, row: any) {
                return row.sellingPartnerId == '' ? `
					<div class="dropdown">
						<button class="btn btn-white border-0 action-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
							<i class="far fa-ellipsis-v fa-lg"></i>
						</button>
						<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
							<li><a id="btn-edit-retailer" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#new-retailer" href="#"><i class="fa-light fa-pencil fa-fw"></i> Edit</a></li>
							<li><a id="btn-resend-link-account-email" class="dropdown-item" href="#"><i class="fa-light fa-envelope fa-fw"></i> Resend Link Account Email</a></li>
						</ul>
					</div>
                 ` : `
					<div class="dropdown">
						<button class="btn btn-white border-0 action-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
							<i class="far fa-ellipsis-v fa-lg"></i>
						</button>
						<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
							<li><a id="btn-edit-retailer" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#new-retailer" href="#"><i class="fa-light fa-pencil fa-fw"></i> Edit</a></li>
						</ul>
					</div>
                 `
            }
        }
    ])

    const tableOptions = ref({
        responsive: true,
        dom: 'rtipl',
        processing: true,
        serverSide: true,
        ajax: {
            url: `/api/company/retailer-companies/page`,
            type: 'POST',
            contentType: 'application/json',
            data: (d: any) => getPostData(d),
            dataSrc: (json: any) => getDataSrc(json),
            error: () => isTestMode.value ? {} : displayAjaxError(),
            columns: tableColumns.value
        },
        language: {
            lengthMenu: '_MENU_'
        },
        lengthMenu: [10, 50, 100, 1000],
        select: {
            style: 'multi',
            selector: 'td.select-checkbox'
        },
    })

    const formatPhone = ((phone: string) => {
        return phone.replace(CustomRegex.matchPhoneNumber, '$1-$2-$3')
    })

    const getPostData = ((d: any) => {
        const pageNumber = d.start / d.length + 1
        const pageSize = d.length
        const search = searchValue.value != '' ? searchValue.value : 'null'
        let isActive = true
        if (selectedStatus.value == 'Deactivated') isActive = false
        if (selectedStatus.value == 'All') isActive = null
        return JSON.stringify({ PageIndex: pageNumber, PageSize: pageSize, Search: search, IsActive: isActive })
    })

    const getDataSrc = ((response: any) => {
        return response.companyList
    })

    const displayAjaxError = (() => {
        snackbarMessage.value = 'An error occurred when loading the table. Please contact support.'
        isSnackbarOpen.value = true
    })

</script>