<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />
    <MbscAlert display="center"
               theme="ios"
               themeVariant="light"
               :message="alertMessage"
               :isOpen="isAlertOpen" />

    <div class="pre-loader" v-if="isLoading"></div>

    <div v-show="hasRefreshToken" class="landing-page light">
        <div class="container">
            <div class="landing-card">
                <div class="supplier-logo">
                    <img :src="imageUrl" alt="Supplier Logo" />
                </div>
                <div class="card">
                    <DataTable :columns="tableColumns"
                               :options="tableOptions"
                               class="table table-hover align-middle m-0"
                               width="100%"
                               ref="table">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Brand</th>
                                <th>SKU</th>
                                <th>MAP</th>
                                <th>ASIN</th>
                            </tr>
                        </thead>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>

    <div v-if="!hasRefreshToken" class="landing-page">
        <div class="landing-container">
            <div class="landing-logo">
                <img src="@/assets/images/logo-white.svg" alt="MapAuthority" />
            </div>
            <div v-if="!linkAccountButtonClicked" class="landing-card">
                <div v-if="imageUrl != ''" class="supplier-logo">
                    <img :src="imageUrl" alt="Supplier Logo" />
                </div>
                <h1 class="landing-headline mb-3">Welcome to MapAuthority</h1>
                <p class="landing-copy mb-5">
                    We're excited to have you as part of our trusted reseller network. To ensure a consistent and fair marketplace for everyone, we've partnered with MapAuthority, a leading solution for monitoring and enforcing MAP compliance.
                    By linking your Amazon Seller Central account, you'll gain a level playing field and protected sales opportunities.
                    What's Next?
                    Click the button below to link your account. You'll need to use the primary login credentials for your Amazon Seller Central account to complete the process.
                    If you have any questions or need assistance, please reach out to us at info@mapauthority.com.
                </p>
                <div class="text-center">
                    <button type="button"
                            class="btn btn-lg btn-primary btn-round hvr-grow"
                            @click="onLinkAccountClicked">
                        Link Amazon Account
                    </button>
                </div>
            </div>
            <div v-if="linkAccountButtonClicked" class="landing-card">
                <div class="supplier-logo">
                    <img :src="imageUrl" alt="Supplier Logo" />
                </div>
                <p class="landing-copy mb-5">This page can now be closed or refreshed.</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

    import axios from 'axios'
    import { MbscAlert, MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import DataTable from 'datatables.net-vue3'
    import DataTablesCore from 'datatables.net-bs5'
    import { onMounted, ref } from 'vue'
    import { useCookie } from 'vue-cookie-next'
    import { useRoute, useRouter } from 'vue-router'

    DataTable.use(DataTablesCore)

    const alertMessage = ref('')
    const cookies = useCookie()
    const hasRefreshToken = ref(true)
    const imageUrl = ref('')
    const isAlertOpen = ref(false)
    const isSnackbarOpen = ref(false)
    const isTestMode = ref(false)
    const linkAccountButtonClicked = ref(false)
    const route = useRoute()
    const router = useRouter()
    const snackbarMessage = ref('')

    onMounted(async () => {
        await handleRedirectFromAmazon()
    })

    const handleRedirectFromAmazon = (async () => {
        if (route.query.spapi_oauth_code) {
            if (!(route.query.state) || !(route.query.state == cookies.getCookie('state'))) {
                showAlert('State from amazon does not match cookie. Please try again.')
                window.close()
            } else {
                try {
                    let request = {
                        linkAmazonAccountRequestGuid: cookies.getCookie('linkAmazonAccountRequestGuid'),
                        oAuthCode: route.query.spapi_oauth_code,
                        sellingPartnerId: route.query.selling_partner_id
                    }
                    await axios.put('/api/retailer-landing/lwa-info', request)
                } catch (error) {
                    showSnackbar('An error occurred. Please contact support.')
                } finally {
                    const queryParams = { ...route.query }
                    delete queryParams.spapi_oauth_code
                    delete queryParams.selling_partner_id
                    delete queryParams.state
                    router.replace({ query: queryParams })
                    cookies.removeCookie('linkAmazonAccountRequestGuid')
                    cookies.removeCookie('state')
                    window.close()
                }
            }
        }
    })

    const onLinkAccountClicked = (async () => {
        try {
            const lwaInfo = (await axios.get('/api/company/lwa-info')).data

            const state = Math.random().toString(36).substring(2)
            cookies.setCookie('state', state, { expire: '30min', secure: 'true' })
            cookies.setCookie('linkAmazonAccountRequestGuid', route.query.linkAmazonAccountRequestGuid, { expire: '30min', secure: 'true' })
            const appId = encodeURIComponent(lwaInfo.appId)
            const redirectUri = encodeURIComponent(lwaInfo.retailerLandingRedirectUri)
            const betaVersion = lwaInfo.isBetaVersion ? '&version=beta' : ''

            const authUri = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${appId}&state=${state}&redirect_uri=${redirectUri}${betaVersion}`

            const width = 1200
            const height = 800
            const left = (screen.width - width) / 2
            const top = (screen.height - height) / 2

            window.open(authUri, '_blank', `width=${width},height=${height},left=${left},top=${top}`);

            linkAccountButtonClicked.value = true

        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const showAlert = ((message: string) => {
        alertMessage.value = message
        isAlertOpen.value = true
    })

    const showSnackbar = ((msg: string) => {
        snackbarMessage.value = msg
        isSnackbarOpen.value = true
    })

    const tableColumns = ref([
        {
            data: 'title',
            render: function (data: any, type: any, row: any) {
                return `<div class="row">
                             <div class="col-2">
                                 <img src="${row.imageUrl}" alt="" />
                             </div>
                             <div class="col">
                                 ${data}
                             </div>
                         </div>`
            }
        },
        {
            data: 'brandName'
        },
        {
            data: 'sku',
            render: function (data: any) {
                return `<span class="word-wrap">${data}</span>`
            }
        },
        {
            data: 'map',
            render: function (data: any) {
                // Format the data as currency
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data)
            }
        },
        {
            data: 'asin'
        }
    ])

    const tableOptions = ref({
        responsive: true,
        dom: 'rtipl',
        processing: true,
        serverSide: true,
        ajax: {
            url: `/api/retailer-landing/page`,
            type: 'POST',
            contentType: 'application/json',
            data: (d: any) => getPostData(d),
            dataSrc: (json: any) => getDataSrc(json),
            error: () => isTestMode.value ? {} : displayAjaxError(),
            columns: tableColumns.value
        },
        language: {
            lengthMenu: '_MENU_'
        },
        lengthMenu: [10, 50, 100, 1000],
        select: {
            style: 'multi',
            selector: 'td.select-checkbox'
        },
    })

    const getPostData = ((d: any) => {
        const pageNumber = d.start / d.length + 1
        const pageSize = d.length
        return JSON.stringify({ LinkAmazonAccountRequestGuid: route.query.linkAmazonAccountRequestGuid, PageIndex: pageNumber, PageSize: pageSize })
    })

    const getDataSrc = ((response: any) => {
        hasRefreshToken.value = response.hasRefreshToken
        imageUrl.value = response.imageUrl
        return response.productList
    })

    const displayAjaxError = (() => {
        snackbarMessage.value = 'An error occurred when loading the table. Please contact support.'
        isSnackbarOpen.value = true
    })

</script>