<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />

    <div class="pre-loader" v-if="isLoading"></div>

    <div class="site-container">
        <AdminMenu />
        <div class="admin-page-content">
            <div class="container">
                <UserProfileDropdown />
                <div class="row align-items-center mb-5">
                    <div class="col-md-auto mb-3 mb-md-0">
                        <h2 class="m-0 text-strong"><i class="fal fa-tag"></i> Approve Brands</h2>
                    </div>
                </div>
                <ul class="nav nav-underline mb-4">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#" id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending-tab-pane" role="tab" aria-controls="pending-tab-pane" aria-selected="true">Pending <span class="badge text-bg-primary text-white text-600">{{ filteredPendingBrands.length }}</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" id="approved-tab" data-bs-toggle="tab" data-bs-target="#approved-tab-pane" role="tab" aria-controls="approved-tab-pane" aria-selected="false">Approved</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" id="denied-tab" data-bs-toggle="tab" data-bs-target="#denied-tab-pane" role="tab" aria-controls="denied-tab-pane" aria-selected="false">Denied</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <!-- pending tab -->
                    <div class="tab-pane fade show active" id="pending-tab-pane" role="tabpanel" aria-labelledby="pending-tab" tabindex="0">
                        <div class="input-group input-group-lg mb-3">
                            <input type="text"
                                   class="form-control"
                                   placeholder="Search brands..."
                                   aria-label="Search brands..."
                                   v-model="searchPendingBrandsValue"
                                   v-on:keyup.enter="onSearchPendingBrandsClicked()">
                            <button id="btn-search-pending"
                                    class="btn btn-white"
                                    type="button"
                                    @click="onSearchPendingBrandsClicked()">
                                <i class="fal fa-search"></i>
                            </button>
                        </div>
                        <div class="card">
                            <table class="table table-hover align-middle m-0">
                                <thead>
                                    <tr>
                                        <th>Brand</th>
                                        <th>Supplier</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="brand in filteredPendingBrands"
                                        :key="brand.id">
                                        <td class="table-pending-brand-name">
                                            {{ brand.brandName }}
                                        </td>
                                        <td class="table-pending-company-name">
                                            {{ brand.companyName }}
                                        </td>
                                        <td class="text-right">
                                            <div class="btn-group btn-group-sm" role="group">
                                                <button type="button"
                                                        class="btn btn-outline-secondary"
                                                        @click="onChangeApprovalClicked(brand.id, brand.companyId, brand.brandName, 'Approved', '')">
                                                    <i class="fas fa-check-circle text-success"></i> Approve
                                                </button>
                                                <button type="button"
                                                        class="btn btn-outline-secondary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#deny-reason"
                                                        @click="onDenyClicked(brand.id, brand.companyId, brand.brandName, 'Denied')">
                                                    <i class="fas fa-times-circle text-danger"></i> Deny
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- approved tab -->
                    <div class="tab-pane fade" id="approved-tab-pane" role="tabpanel" aria-labelledby="approved-tab" tabindex="0">
                        <div class="input-group input-group-lg mb-3">
                            <input type="text"
                                   class="form-control"
                                   placeholder="Search brands..."
                                   aria-label="Search brands..."
                                   v-model="searchApprovedBrandsValue"
                                   v-on:keyup.enter="onSearchApprovedBrandsClicked()">
                            <button id="btn-search-approved"
                                    class="btn btn-white"
                                    type="button"
                                    @click="onSearchApprovedBrandsClicked()">
                                <i class="fal fa-search"></i>
                            </button>
                        </div>
                        <div class="card">
                            <table class="table table-hover align-middle m-0">
                                <thead>
                                    <tr>
                                        <th>Brand</th>
                                        <th>Supplier</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="brand in filteredApprovedBrands"
                                        :key="brand.id">
                                        <td class="table-approved-brand-name">
                                            {{ brand.brandName }}
                                        </td>
                                        <td class="table-approved-company-name">
                                            {{ brand.companyName }}
                                        </td>
                                        <td class="text-right">
                                            <div class="dropdown">
                                                <button class="btn btn-white border-0 action-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="far fa-ellipsis-v fa-lg"></i>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <a class="dropdown-item"
                                                           data-bs-toggle="modal"
                                                           data-bs-target="#deny-reason"
                                                           href="#"
                                                           @click="onDenyClicked(brand.id, brand.companyId, brand.brandName, 'Denied')">
                                                            <i class="fa-light fa-times-circle fa-fw"></i> Move to denied
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item"
                                                           href="#"
                                                           @click.prevent="onChangeApprovalClicked(brand.id, brand.companyId, brand.brandName, 'Pending', '')">
                                                            <i class="fa-light fa-hourglass fa-fw"></i> Move to pending
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- denied tab -->
                    <div class="tab-pane fade" id="denied-tab-pane" role="tabpanel" aria-labelledby="denied-tab" tabindex="0">
                        <div class="card">
                            <table class="table table-hover align-middle m-0">
                                <thead>
                                    <tr>
                                        <th>Brand</th>
                                        <th>Supplier</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="brand in filteredDeniedBrands"
                                        :key="brand.id">
                                        <td class="table-denied-brand-name">
                                            {{ brand.brandName }}
                                        </td>
                                        <td class="table-denied-company-name">
                                            {{ brand.companyName }}
                                        </td>
                                        <td class="text-right">
                                            <div class="dropdown">
                                                <button class="btn btn-white border-0 action-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="far fa-ellipsis-v fa-lg"></i>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <a class="dropdown-item"
                                                           href="#"
                                                           @click.prevent="onChangeApprovalClicked(brand.id, brand.companyId, brand.brandName, 'Approved', '')">
                                                            <i class="fa-light fa-check-circle fa-fw"></i> Move to approved
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item"
                                                           href="#"
                                                           @click.prevent="onChangeApprovalClicked(brand.id, brand.companyId, brand.brandName, 'Pending', '')">
                                                            <i class="fa-light fa-hourglass fa-fw"></i> Move to pending
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <UserFooter />
    </div>

    <!-- Deny Brand Modal -->
    <div class="modal fade" id="deny-reason" tabindex="-1" aria-labelledby="deny-reason" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">Deny Brand</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="denyReason" class="form-label">Deny Reason<sup class="text-danger">*</sup></label>
                            <textarea class="form-control"
                                      placeholder="Reason..."
                                      rows="8"
                                      v-model="denyBrand.approvalNote"
                                      v-bind:class="{'is-invalid' : !validDenyReason() && denyReasonBlured}" v-on:blur="denyReasonBlured = true"></textarea>
                            <small>This reason will be emailed to the brand</small>
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 1000 characters or less.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-outline-secondary btn-round me-2"
                            data-bs-dismiss="modal"
                            ref="closeModalButton">
                        Cancel
                    </button>
                    <button type="button"
                            class="btn btn-danger text-white btn-round"
                            @click="onChangeApprovalClicked(denyBrand.id, denyBrand.companyId, denyBrand.brandName, 'Denied', denyBrand.approvalNote)">
                        Deny Brand
                    </button>
                </div>
            </div>
        </div>
    </div>


</template>

<script lang="ts" setup>
    import UserFooter from '@/components/UserFooter.vue'
    import AdminMenu from '@/components/AdminMenu.vue'
    import UserProfileDropdown from '@/components/UserProfileDropdown.vue'

    import { computed, onMounted, ref } from 'vue'

    import axios from 'axios'
    import { CustomRegex } from '@/assets/CustomRegex'
    import { MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { BrandDto } from '@/assets/types'

    const isLoading = ref(false)
    const isSnackbarOpen = ref(false)
    const snackbarMessage = ref('')

    const emptyBrand: Readonly<BrandDto> = {
        id: null,
        companyId: null,
        brandName: '',
        companyName: null,
        approval: null,
        approvalNote: '',
        isActive: true
    }

    const brandDataDto = ref([])
    const closeModalButton = ref<HTMLButtonElement>()
    const denyBrand = ref<BrandDto>(JSON.parse(JSON.stringify(emptyBrand)))
    const denyReasonBlured = ref(false)
    const searchApprovedBrandsValue = ref('')
    const searchApprovedBrandsValueFilter = ref('')
    const searchPendingBrandsValue = ref('')
    const searchPendingBrandsValueFilter = ref('')

    onMounted(async () => {
        await loadData()
    })

    const loadData = (async () => {
        try {
            brandDataDto.value = (await axios.get('/api/brand')).data
        } catch {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onSearchPendingBrandsClicked = (() => {
        searchPendingBrandsValueFilter.value = searchPendingBrandsValue.value
    })

    const onSearchApprovedBrandsClicked = (() => {
        searchApprovedBrandsValueFilter.value = searchApprovedBrandsValue.value
    })

    const filteredPendingBrands = computed(() => {
        let result = brandDataDto.value

        result = result.filter((brand) => brand.approval === 'Pending' && brand.isActive == true)

        if (searchPendingBrandsValueFilter.value !== '' && result.length > 0) {
            result = result.filter((brand) =>
                brand.brandName.toLowerCase().includes(searchPendingBrandsValueFilter.value.toLowerCase())
            )
        }

        return result
    })

    const filteredApprovedBrands = computed(() => {
        let result = brandDataDto.value

        result = result.filter((brand) => brand.approval === 'Approved' && brand.isActive == true)

        if (searchApprovedBrandsValueFilter.value !== '' && result.length > 0) {
            result = result.filter((brand) =>
                brand.brandName.toLowerCase().includes(searchApprovedBrandsValueFilter.value.toLowerCase())
            )
        }

        return result
    })

    const filteredDeniedBrands = computed(() => {
        let result = brandDataDto.value
        result = result.filter((brand) => brand.approval === 'Denied' && brand.isActive == true)
        return result
    })

    const onDenyClicked = (brandId: number, companyId: number, brandName: string, approval: string) => {
        denyBrand.value.id = brandId
        denyBrand.value.companyId = companyId
        denyBrand.value.brandName = brandName
        denyBrand.value.approval = approval
    }

    const onChangeApprovalClicked = (async (brandId: number, companyId: number, brandName: string, approval: string, approvalNote: string) => {
        try {
            if (approval === 'Denied' && !validate()) {
                return
            }
            let brand: BrandDto = {
                id: brandId,
                companyId: companyId,
                brandName: brandName,
                companyName: null,
                approval: approval,
                approvalNote: approvalNote,
                isActive: null
            }
            await axios.put('/api/brand/approval', brand)
            showSnackbar('Brand approval has been changed.')
            closeModalButton.value?.click()
            await loadData()
            denyReasonBlured.value = false
            denyBrand.value = JSON.parse(JSON.stringify(emptyBrand))
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const validate = (() => {
        denyReasonBlured.value = true
        return validDenyReason()
    })

    const validDenyReason = (() => {
        let regex = CustomRegex.isBetweenOneAndOneThousandChars
        if (regex.test(denyBrand.value.approvalNote.toLowerCase())) {
            return true
        }
    })

    const showSnackbar = ((msg: string) => {
        snackbarMessage.value = msg
        isSnackbarOpen.value = true
    })

</script>