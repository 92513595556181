<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />
    <div class="pre-loader" v-if="isLoading"></div>

    <div class="site-container">
        <AdminMenu />
        <div class="admin-page-content">
            <div class="container">
                <UserProfileDropdown />
                <div class="row align-items-center mb-5">
                    <div class="col-md-auto mb-3 mb-md-0">
                        <h2 class="m-0 text-strong"><i class="fal fa-shop"></i> Suppliers</h2>
                    </div>
                    <div class="col-md-auto">
                        <button type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#new-supplier"
                                id="btn-add-new-supplier"
                                class="btn btn-sm px-3 btn-primary btn-round hvr-grow mobile-100"
                                @click="onNewCompanyClicked()">
                            <i class="fal fa-plus"></i> New
                        </button>
                    </div>
                </div>
                <div class="input-group input-group-lg mb-3">
                    <input type="text"
                           class="form-control"
                           placeholder="Search suppliers..."
                           aria-label="Search suppliers..."
                           v-model="searchValue"
                           v-on:keyup.enter="onSearchClicked()">
                    <button class="btn btn-white"
                            id="btn-search"
                            type="button"
                            @click="onSearchClicked()">
                        <i class="fal fa-search"></i>
                    </button>
                </div>
                <div class="row mb-4">
                    <div class="col-xxl-2 col-xl-5">
                        <label class="form-label small">Status</label>
                        <div class="dropdown w-100">
                            <button class="btn btn-white btn-sm dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{ selectedStatus }}</span>
                            </button>
                            <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                <li v-for="option in statusOptions" :key="option">
                                    <a class="dropdown-item"
                                       :class="{'active': selectedStatus == option }"
                                       href="#"
                                       @click.prevent="selectedStatus = option">{{ option }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <table class="table table-hover align-middle m-0">
                        <thead>
                            <tr>
                                <th>Supplier</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Current/Max Asins</th>
                                <th>Can LWA</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="company in supplierCompanies.companyList" 
                                :key="company.id"
                                :class="{'deactivated' : !company.isActive}">
                                <td class="table-name">{{ company.name }}</td>
                                <td class="table-email">{{ company.email }}</td>
                                <td class="table-phone">{{ formatPhone(company.phone) }}</td>
                                <td class="table-max-allowed-asins">{{ company.currentAsinCount + '/' + company.maxAllowedAsins }}</td>
                                <td class="table-lwa-status">{{ company.isAllowedToLWA ? 'Yes' : 'No' }}</td>
                                <td class="table-status">{{ company.isActive ? 'Active' : 'Deactivated' }}</td>
                                <td class="text-right">
                                    <div class="dropdown">
                                        <button class="btn btn-white border-0 action-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="far fa-ellipsis-v fa-lg"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a class="dropdown-item"
                                                   id="dropdown-item-edit"
                                                   data-bs-toggle="modal"
                                                   data-bs-target="#new-supplier"
                                                   href="#"
                                                   @click.prevent="onEditCompanyClicked(company)"><i class="fa-light fa-pencil fa-fw"></i> Edit</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="p-3 d-md-flex align-items-center justify-content-md-between justify-content-center text-center text-md-left"
                         v-if="supplierCompanies.recordsTotal > 0">

                        <p class="m-md-0 mb-3" id="result-message">
                            Showing {{ pageIndex == 1 ? pageIndex : ((pageIndex - 1) * pageSize) + 1 }} -
                            {{ supplierCompanies.recordsTotal < pageIndex * pageSize ? supplierCompanies.recordsTotal : (pageIndex * pageSize) }} of
                            {{ supplierCompanies.recordsTotal }} results
                        </p>
                        <nav>
                            <ul class="pagination pagination-lg m-0 justify-content-center justify-content-md-end">
                                <li class="page-item"
                                    :class="{'disabled': pageIndex == 1}">
                                    <a class="page-link"
                                       id="btn-previous"
                                       href="#"
                                       aria-label="Previous"
                                       @click.prevent="onPreviousClicked()">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>

                                <li class="page-item"
                                    :class="{'active': pageIndex == 1 }">
                                    <a class="page-link"
                                       href="#"
                                       @click.prevent="onSetClicked(firstPageItemNumber)">{{ firstPageItemNumber }}</a>
                                </li>
                                <li class="page-item"
                                    :class="{'active': secondPageItemNumber == pageIndex }"
                                    v-if="numberOfPages >= secondPageItemNumber && numberOfPages > 1">
                                    <a class="page-link"
                                       href="#"
                                       @click.prevent="onSetClicked(secondPageItemNumber)">{{ secondPageItemNumber }}</a>
                                </li>
                                <li class="page-item"
                                    :class="{'active': pageIndex == numberOfPages }"
                                    v-if="numberOfPages + 1 >= thirdPageItemNumber && numberOfPages > 2">
                                    <a class="page-link"
                                       href="#"
                                       @click.prevent="onSetClicked(thirdPageItemNumber)">{{ thirdPageItemNumber }}</a>
                                </li>
                                <li class="page-item"
                                    :class="{'disabled': pageIndex == numberOfPages }">
                                    <a class="page-link"
                                       id="btn-next"
                                       href="#"
                                       aria-label="Next"
                                       @click.prevent="onNextClicked()">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="card"
                     id="no-suppliers"
                     v-if="supplierCompanies.recordsTotal === 0">
                    <div class="my-4 text-center">
                        <h3 class="mb-3"><i class="fa-regular fa-search"></i></h3>
                        <p>No suppliers found.</p>
                    </div>
                </div>
            </div>
        </div>

        <UserFooter />
    </div>

    <!-- New/Edit Supplier Modal -->
    <div class="modal fade"
         id="new-supplier"
         data-bs-backdrop="static"
         tabindex="-1"
         aria-labelledby="new-supplier"
         aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">{{ isEditing ? 'Edit' : 'New'}} Supplier</h1>
                    <button type="button"
                            ref="closeModalButton"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            @click="isEditing = false"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="companyName" class="form-label">Supplier Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="companyName"
                                   placeholder="Supplier Name"
                                   v-model="addOrEditCompany.name"
                                   v-bind:class="{'is-invalid' : !validCompanyName() && companyNameBlured}" v-on:blur="companyNameBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="companyMaxAllowedAsins" class="form-label">Max Allowed Asins{{ isEditing ? ' (Current Count is ' + addOrEditCompany.currentAsinCount + ')' : '' }}<sup class="text-danger">*</sup></label>
                            <input type="number"
                                   class="form-control"
                                   id="companyMaxAllowedAsins"
                                   v-model="addOrEditCompany.maxAllowedAsins"
                                   placeholder="Supplier Max Allowed Asins"
                                   v-bind:class="{'is-invalid' : !validCompanyMaxAllowedAsins() && companyMaxAllowedAsinsBlured}" v-on:blur="companyMaxAllowedAsinsBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be greater than 0.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="companyEmail" class="form-label">Email</label>
                            <input type="text"
                                   class="form-control"
                                   id="companyEmail"
                                   v-model="addOrEditCompany.email"
                                   placeholder="Supplier Email"
                                   v-bind:class="{'is-invalid' : !validCompanyEmail() && companyEmailBlured}" v-on:blur="companyEmailBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field must contain a valid email.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="companyPhone" class="form-label">Phone</label>
                            <input type="text"
                                   class="form-control"
                                   id="companyPhone"
                                   v-model="addOrEditCompany.phone"
                                   placeholder="Supplier Phone"
                                   v-bind:class="{'is-invalid' : !validCompanyPhone() && companyPhoneBlured}" v-on:blur="companyPhoneBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field must contain a 10 digit phone number.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="companyLWAStatus" class="form-label d-block">LWA Status</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input"
                                       type="checkbox"
                                       role="switch"
                                       id="flexSwitchCheckChecked"
                                       v-model="addOrEditCompany.isAllowedToLWA"
                                       checked>
                                <label class="form-check-label" for="flexSwitchCheckChecked">Can LWA</label>
                            </div>
                        </div>
                        <div class="col-md">
                            <label for="companyStatus" class="form-label d-block">Price Check Freq.</label>
                            <select id="companyStatus" 
                                    class="form-select form-select-sm"
                                    :disabled="!addOrEditCompany.isAllowedToLWA"
                                    v-model="addOrEditCompany.priceCheckFrequency">
                                <option value="0">Auto</option>
                                <option value="24">24 per day</option>
                                <option value="12">12 per day</option>
                                <option value="8">8 per day</option>
                                <option value="6">6 per day</option>
                                <option value="4">4 per day</option>
                                <option value="3">3 per day</option>
                                <option value="2">2 per day</option>
                                <option value="1">1 per day</option>
                            </select>
                       </div>
                        <div class="col-md mb-3">
                            <label for="companyStatus" class="form-label d-block">Status</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input"
                                       type="checkbox"
                                       role="switch"
                                       id="flexSwitchCheckChecked"
                                       v-model="addOrEditCompany.isActive"
                                       checked>
                                <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="id" class="form-label d-block">Company Id</label>
                            <p>{{addOrEditCompany.id}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xxl-4 col-lg-6">
                            <label class="form-label d-block">Supplier Logo</label>
                            <div class="hidden-file-input mb-3">
                                <input accept="image/*"
                                       type="file"
                                       @change="onFileChanged($event)" />
                                <button type="button" class="btn btn-sm px-4 btn-outline-secondary btn-round"><i class="fal fa-upload"></i> Choose File</button>
                            </div>
                            <div class="card no-shadow border position-relative"
                                 v-if="addOrEditCompany.imageUrl != '' || selectedFile != null">
                                <button type="button"
                                        class="floating-action-button top right btn btn-sm btn-white border-0"
                                        @click="onClearFileClicked()">
                                    <i class="fa-regular fa-times"></i>
                                </button>
                                <div class="p-4">
                                    <img class="d-block mx-auto" :src="imageUrl" alt="logo preview" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isEditing">
                        <hr />
                        <h5 class="text-strong mb-4">Supplier Admin User</h5>
                        <div class="row">
                            <div class="col-md mb-3">
                                <label for="userFirstName" class="form-label">First Name<sup class="text-danger">*</sup></label>
                                <input type="text"
                                       class="form-control"
                                       id="userFirstName"
                                       placeholder="First Name"
                                       v-model="addSupplierUser.firstName"
                                       v-bind:class="{'is-invalid' : !validUserFirstName() && userFirstNameBlured}" v-on:blur="userFirstNameBlured = true">
                                <div class="invalid-feedback">
                                    <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                                </div>
                            </div>
                            <div class="col-md mb-3">
                                <label for="userLastName" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                                <input type="text"
                                       class="form-control"
                                       id="userLastName"
                                       placeholder="Last Name"
                                       v-model="addSupplierUser.lastName"
                                       v-bind:class="{'is-invalid' : !validUserLastName() && userLastNameBlured}" v-on:blur="userLastNameBlured = true">
                                <div class="invalid-feedback">
                                    <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mb-3">
                                <label for="userEmail" class="form-label">Email Address<sup class="text-danger">*</sup></label>
                                <input type="email"
                                       class="form-control"
                                       id="userEmail"
                                       placeholder="Email Address"
                                       v-model="addSupplierUser.email"
                                       v-bind:class="{'is-invalid' : !validUserEmail() && userEmailBlured}" v-on:blur="userEmailBlured = true">
                                <div class="invalid-feedback">
                                    <i class="fas fa-exclamation-circle"></i> This field is required and must contain a valid email.
                                </div>
                            </div>
                            <div class="col-md mb-3">
                                <label for="userPhone" class="form-label">Phone<sup class="text-danger">*</sup></label>
                                <input type="text"
                                       class="form-control"
                                       id="userPhone"
                                       placeholder="Phone"
                                       v-model="addSupplierUser.phone"
                                      v-bind:class="{'is-invalid' : !validUserPhone() && userPhoneBlured}" v-on:blur="userPhoneBlured = true">
                                <div class="invalid-feedback">
                                    <i class="fas fa-exclamation-circle"></i> This field is required and must contain a 10 digit phone number.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-outline-secondary btn-round me-2"
                            data-bs-dismiss="modal"
                            @click="isEditing = false">
                        Cancel
                    </button>
                    <button type="button"
                            id="btn-submit"
                            class="btn btn-secondary btn-round"
                            @click="onSubmitClicked()">
                        Save Supplier
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import axios, { AxiosError } from 'axios'
    import { CustomRegex } from '@/assets/CustomRegex'
    import { MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { CompanyDto, CompanyPageDto, SupplierUserDto } from '@/assets/types'
    import AdminMenu from '@/components/AdminMenu.vue'
    import UserFooter from '@/components/UserFooter.vue'
    import UserProfileDropdown from '@/components/UserProfileDropdown.vue'
    import { computed, onMounted, ref, watch } from 'vue'

    const emptySupplierCompanyPage: Readonly<CompanyPageDto> = {
        recordsTotal: 0,
        companyList: []
    }

    const emptySupplierCompany: Readonly<CompanyDto> = {
        id: 0,
        name: '',
        firstName: null,
        lastName: null,
        email: '',
        phone: '',
        imageUrl: '',
        maxAllowedAsins: 100,
        currentAsinCount: 0,
        isAllowedToLWA: false,
        priceCheckFrequency: 0,
        isActive: true
    }

    const emptySupplierUser: Readonly<SupplierUserDto> = {
        userId: null,
        companyIds: [],
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        isActive: true,
        isSuperAdmin: true
    }

    const addOrEditCompany = ref<CompanyDto>(JSON.parse(JSON.stringify(emptySupplierCompany)))
    const addSupplierUser = ref<SupplierUserDto>(JSON.parse(JSON.stringify(emptySupplierUser)))
    const closeModalButton = ref<HTMLButtonElement>()
    const isEditing = ref(false)
    const isLoading = ref(false)
    const isSnackbarOpen = ref(false)
    const pageIndex = ref(1)
    const pageSize = ref(10)
    const searchValue = ref('')
    const selectedFile = ref<File>()
    const selectedStatus = ref('Active')
    const snackbarMessage = ref('')
    const supplierCompanies = ref<CompanyPageDto>(JSON.parse(JSON.stringify(emptySupplierCompanyPage)))
    const statusOptions = ref(['All', 'Active', 'Deactivated'])

    const companyNameBlured = ref(false)
    const companyEmailBlured = ref(false)
    const companyPhoneBlured = ref(false)
    const companyMaxAllowedAsinsBlured = ref(false)

    const userFirstNameBlured = ref(false)
    const userLastNameBlured = ref(false)
    const userEmailBlured = ref(false)
    const userPhoneBlured = ref(false)

    onMounted(async () => {
        await loadSupplierCompanies()
    })

    const onSearchClicked = (async () => {
        if (pageIndex.value != 1) {
            pageIndex.value = 1
        } else {
            await loadSupplierCompanies()
        }
    })

    const onNewCompanyClicked = (() => {
        resetValidation()
        addOrEditCompany.value = JSON.parse(JSON.stringify(emptySupplierCompany))
        addSupplierUser.value = JSON.parse(JSON.stringify(emptySupplierUser))
        isEditing.value = false
    })

    const onEditCompanyClicked = ((company: CompanyDto) => {
        resetValidation()
        addOrEditCompany.value = JSON.parse(JSON.stringify(company))
        isEditing.value = true
    })

    const resetValidation = (() => {
        companyNameBlured.value = false
        companyEmailBlured.value = false
        companyPhoneBlured.value = false
        companyMaxAllowedAsinsBlured.value = false
        userFirstNameBlured.value = false
        userLastNameBlured.value = false
        userEmailBlured.value = false
        userPhoneBlured.value = false
    })

    const onSubmitClicked = (async () => {
        try {
            let companyIsInvalid = !validateCompany()
            let userIsInvalidAndIsAdding = !validateUser() && !isEditing.value
            if (companyIsInvalid || userIsInvalidAndIsAdding) {
                return
            }

            addOrEditCompany.value.phone = addOrEditCompany.value.phone.replace(/\D/g, '')
            addOrEditCompany.value.priceCheckFrequency = parseInt(addOrEditCompany.value.priceCheckFrequency)
            if (isEditing.value) {
                isLoading.value = true
                let formData = new FormData()
                formData.append('companyDto', JSON.stringify(addOrEditCompany.value))
                if (selectedFile.value != null) formData.append('file', selectedFile.value)

                await axios.put('/api/admin/supplier-company', formData)
                showSnackbar('Supplier company was updated.')
            } else {
                isLoading.value = true
                addSupplierUser.value.phone = addSupplierUser.value.phone.replace(/\D/g, '')

                let formData = new FormData()
                formData.append('companyDto', JSON.stringify(addOrEditCompany.value))
                formData.append('userDto', JSON.stringify(addSupplierUser.value))
                if (selectedFile.value != null) formData.append('file', selectedFile.value)

                await axios.post('/api/admin/supplier-company', formData)
                showSnackbar('Supplier company was added.')
            }
            resetValidation()
            closeModalButton.value?.click()
            await loadSupplierCompanies()
        } catch (error) {
            const axiosError = error as AxiosError
            if (axiosError.response?.status == 400) {
                showSnackbar('' + axiosError.response?.data)
            } else {
                showSnackbar('An error occurred. Please contact support.')
            }
        } finally {
            selectedFile.value = undefined
            isLoading.value = false
        }
    })

    const onFileChanged = (($event: Event) => {
        const target = $event.target as HTMLInputElement
        const maxFileSizeOf5Mb = 5 * 1024 * 1024

        if (target && target.files) {
            if (target.files[0].size > maxFileSizeOf5Mb) {
                showSnackbar('Image must be 5 MB or less.')
                return
            }

            selectedFile.value = target.files[0]
        }
    })

    const onClearFileClicked = (() => {
        selectedFile.value = undefined
        addOrEditCompany.value.imageUrl = ''
    })

    const imageUrl = computed(() => {
        return selectedFile.value != null ? URL.createObjectURL(selectedFile.value) : addOrEditCompany.value.imageUrl
    })

    const validateCompany = (() => {
        companyNameBlured.value = true
        companyEmailBlured.value = true
        companyPhoneBlured.value = true
        companyMaxAllowedAsinsBlured.value = true
        return validCompanyName() && validCompanyEmail() && validCompanyPhone() && validCompanyMaxAllowedAsins()
    })

    const validCompanyName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(addOrEditCompany.value.name.toLowerCase())) {
            return true
        }
    })

    const validCompanyEmail = (() => {
        let regex = CustomRegex.isEmailAddress
        if (addOrEditCompany.value.email.trim() == '' || regex.test(addOrEditCompany.value.email.toLowerCase())) {
            return true
        }
    })

    const validCompanyPhone = (() => {
        let regex = CustomRegex.isPhoneNumber
        if (addOrEditCompany.value.phone.trim() == '' || regex.test(addOrEditCompany.value.phone.toLowerCase())) {
            return true
        }
    })

    const validCompanyMaxAllowedAsins = (() => {
        if (addOrEditCompany.value.maxAllowedAsins > 0) {
            return true
        }
    })

    const validateUser = (() => {
        userFirstNameBlured.value = true
        userLastNameBlured.value = true
        userEmailBlured.value = true
        userPhoneBlured.value = true
        return validUserFirstName() && validUserLastName() && validUserEmail() && validUserPhone()
    })

    const validUserFirstName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(addSupplierUser.value.firstName.toLowerCase())) {
            return true
        }
    })

    const validUserLastName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(addSupplierUser.value.lastName.toLowerCase())) {
            return true
        }
    })

    const validUserEmail = (() => {
        let regex = CustomRegex.isEmailAddress
        if (regex.test(addSupplierUser.value.email.toLowerCase())) {
            return true
        }
    })

    const validUserPhone = (() => {
        let regex = CustomRegex.isPhoneNumber
        if (regex.test(addSupplierUser.value.phone.toLowerCase())) {
            return true
        }
    })

    const onNextClicked = (async () => {
        pageIndex.value++
    })

    const onPreviousClicked = (async () => {
        if (pageIndex.value > 1) pageIndex.value--
    })

    const onSetClicked = ((page: number) => {
        pageIndex.value = page
    })

    watch(selectedStatus, async () => {
        if (pageIndex.value != 1) {
            pageIndex.value = 1
        } else {
            await loadSupplierCompanies()
        }
    })

    watch(pageIndex, async () => {
        await loadSupplierCompanies()
    })

    const loadSupplierCompanies = (async () => {
        try {
            let endpoint: string = endpointBuilder()
            supplierCompanies.value = (await axios.get(endpoint)).data
        } catch (error) {
            showSnackbar('Failed to retrieve supplier companies. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const endpointBuilder = ((): string => {
        let endpoint: string = `/api/admin/supplier-companies/page/${pageIndex.value}/${pageSize.value}`

        if (searchValue.value == '' && selectedStatus.value == 'All') {
            return endpoint
        }

        if (searchValue.value != '') {
            endpoint = `${endpoint}/${searchValue.value}`
        } else {
            endpoint = `${endpoint}/null`
        }

        if (selectedStatus.value == 'Active') {
            endpoint = `${endpoint}/true`
        } else if (selectedStatus.value == 'Deactivated') {
            endpoint = `${endpoint}/false`
        }

        return endpoint
    })

    const formatPhone = ((phone: string) => {
        return phone.replace(CustomRegex.matchPhoneNumber, '$1-$2-$3')
    })

    const showSnackbar = ((message: string) => {
        snackbarMessage.value = message
        isSnackbarOpen.value = true
    })

    const numberOfPages = computed(() => {
        return Math.ceil(supplierCompanies.value.recordsTotal / pageSize.value)
    })

    const firstPageItemNumber = computed(() => {
        let result = pageIndex.value - 1

        if (pageIndex.value == 1 || numberOfPages.value == 1 || numberOfPages.value == 2) {
            result = 1
        } else if (numberOfPages.value == pageIndex.value) {
            result = pageIndex.value - 2
        }

        return result
    })

    const secondPageItemNumber = computed(() => {
        let result = pageIndex.value

        if (pageIndex.value == 1 || numberOfPages.value == 2) {
            result = 2
        } else if (numberOfPages.value == pageIndex.value) {
            result = pageIndex.value - 1
        }

        return result
    })

    const thirdPageItemNumber = computed(() => {
        let result = pageIndex.value + 1

        if (pageIndex.value == 1 || numberOfPages.value == 3) {
            result = 3
        } else if (numberOfPages.value == pageIndex.value) {
            result = pageIndex.value
        }

        return result
    })
</script>