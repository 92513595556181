<template>
    <MbscSnackbar display="top"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />
    <MbscAlert display="center"
               theme="ios"
               themeVariant="dark"
               cssClass="mobiscroll-dark"
               :message="alertMessage"
               :isOpen="isAlertOpen"
               @close="onAlertClosed" />

    <div class="login">

        <div class="pre-loader" v-if="isLoading"></div>

        <div class="login-container">

            <div class="login-box">
                <img src="@/assets/images/logo-white.svg" alt="MapAuthority" class="logo" />
                <!-- set password form -->
                <form @submit.prevent="onFormSubmit">
                    <h4 class="text-center mb-2">Set Password</h4>
                    <p class="text-center mb-4">Use at least 8 characters. Don't use a<br />password from another site, and don't<br />include any personal information such as<br />your name or date of birth.</p>
                    <div class="row mb-3">
                        <div class="col-auto login-icon"><i class="fal fa-lock fa-2x mt-3"></i></div>
                        <div class="col">
                            <input type="password"
                                   class="form-control form-control-lg dark mb-4"
                                   placeholder="Password"
                                   v-model="password" />
                            <div class="password-suggestions">
                                <div class="progress mb-3" style="height: 10px">
                                    <div class="progress-bar"
                                         :class="{ 'bg-danger': passwordScore.classDanger, 'bg-warning': passwordScore.classWarning, 'bg-success': passwordScore.classSuccess }"
                                         role="progressbar"
                                         :style="{'width': passwordScore.percent + '%'}"
                                         aria-valuenow="25"
                                         aria-valuemin="0"
                                         aria-valuemax="100"></div>
                                </div>
                                <h5 class="mb-1">
                                    Password Strength:
                                    <span :class="{ 'text-danger': passwordScore.classDanger, 'text-warning': passwordScore.classWarning, 'text-success': passwordScore.classSuccess }">{{ passwordScore.text }}</span>
                                </h5>
                                <p v-if="passwordScore.suggestions">Suggestions: {{ passwordScore.suggestions }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center mb-5">
                        <div class="col-auto login-icon"><i class="fal fa-lock fa-2x"></i></div>
                        <div class="col">
                            <input type="password"
                                   class="password-repeat form-control form-control-lg dark"
                                   placeholder="Repeat Password"
                                   v-model="passwordRepeat" />
                        </div>
                    </div>
                    <div class="d-grid mb-5">
                        <button type="submit"
                                class="btn btn-primary btn-lg mx-auto btn-round px-5 hvr-grow">
                            Set Password
                        </button>
                    </div>
                </form>
            </div>

        </div>

        <LoginFooter />

    </div>
</template>

<script lang="ts" setup>
    import axios from 'axios'
    import LoginFooter from '@/components/LoginFooter.vue'
    import { MbscAlert, MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { ref, computed, onMounted } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import zxcvbn from 'zxcvbn'

    const route = useRoute()
    const router = useRouter()

    const alertMessage = ref('')
    const alertRoute = ref('')
    const isLoading = ref(true)
    const isAlertOpen = ref(false)
    const isSnackbarOpen = ref(false)
    const password = ref('')
    const passwordRepeat = ref('')
    const snackbarMessage = ref('')

    onMounted(async () => {
        try {
            await axios.post('/api/user/verify-password-reset-request', {
                userId: route.query.userId,
                guid: route.query.guid
            })
        } catch (error) {
            showAlert('Link has expired. You will be redirected to the forgot password page. Please submit a new password reset request.', 'forgot-password')
        } finally {
            isLoading.value = false
        }
    })

    const onAlertClosed = (() => {
        router.push({ name: alertRoute.value })
    })

    const onFormSubmit = (async () => {

        if (password.value != passwordRepeat.value) {
            showSnackbar('Passwords do not match.')
            return
        }

        if (passwordScore.value.percent < 75) {
            showSnackbar('Password must have a score of Good or Great.')
            return
        }

        try {
            isLoading.value = true
            await axios.post('/api/user/password-set', {
                userId: route.query.userId,
                guid: route.query.guid,
                password: password.value
            })
            showAlert('Password was set.', 'login')
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const passwordScore = computed(() => {
        const zxcvbnResult = zxcvbn(password.value)
        const score = zxcvbnResult.score
        const suggestions = zxcvbnResult.feedback.suggestions.join(' ')

        let data: any

        switch (score) {
            case 1: {
                data = {
                    classDanger: true,
                    classWarning: false,
                    classSuccess: false,
                    percent: 25,
                    text: 'Too Weak'
                }
                break
            }
            case 2: {
                data = {
                    classDanger: false,
                    classWarning: true,
                    classSuccess: false,
                    percent: 50,
                    text: 'Weak'
                }
                break
            }
            case 3: {
                data = {
                    classDanger: false,
                    classWarning: false,
                    classSuccess: true,
                    percent: 75,
                    text: 'Good'
                }
                break
            }
            case 4: {
                data = {
                    classDanger: false,
                    classWarning: false,
                    classSuccess: true,
                    percent: 100,
                    text: 'Great!'
                }
                break
            }
            default: {
                data = {
                    classDanger: true,
                    classWarning: false,
                    classSuccess: false,
                    percent: 0,
                    text: 'Too Weak'
                }
                break
            }
        }

        data.suggestions = suggestions

        return data
    })

    const showAlert = ((message: string, routeName: string) => {
        alertMessage.value = message
        isAlertOpen.value = true
        alertRoute.value = routeName
    })

    const showSnackbar = ((message: string) => {
        snackbarMessage.value = message
        isSnackbarOpen.value = true
    })

</script>