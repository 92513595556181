<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />
    <div class="pre-loader" v-if="isLoading"></div>

    <div class="site-container">
        <AdminMenu />
        <div class="admin-page-content">
            <div class="container">
                <UserProfileDropdown />
                <div class="row align-items-center mb-5">
                    <div class="col-md-auto mb-3 mb-md-0">
                        <h2 class="m-0 text-strong"><i class="fal fa-user"></i> Admin Users</h2>
                    </div>
                    <div class="col-md-auto">
                        <button type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#new-admin-user"
                                id="btn-add-new-user"
                                class="btn btn-sm px-3 btn-primary btn-round hvr-grow mobile-100"
                                @click="onNewUserClicked()">
                            <i class="fal fa-plus"></i> New
                        </button>
                    </div>
                </div>
                <div class="input-group input-group-lg mb-3">
                    <input type="text"
                           class="form-control input-search"
                           placeholder="Search admin users..."
                           aria-label="Search admin users..."
                           v-model="searchValue"
                           v-on:keyup.enter="onSearchClicked()">
                    <button class="btn btn-white btn-search"
                            type="button"
                            @click="onSearchClicked()">
                        <i class="fal fa-search"></i>
                    </button>
                </div>
                <div class="row mb-4">
                    <div class="col-xxl-2 col-xl-5">
                        <label class="form-label small">Status</label>
                        <div class="dropdown w-100">
                            <button class="btn btn-white btn-sm dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{ selectedStatus }}</span>
                            </button>
                            <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                <li v-for="option in statusOptions" :key="option">
                                    <a class="dropdown-item"
                                       :class="{'active': selectedStatus == option }"
                                       href="#"
                                       @click.prevent="selectedStatus = option">{{ option }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <table class="table table-hover align-middle m-0">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Phone</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="admin in filteredSuperAdminUsers" 
                                :key="admin.id" 
                                :class="{'deactivated' : !admin.isActive}">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <div class="avatar avatar-sm table-avatar">{{ admin.firstName.charAt(0) + admin.lastName.charAt(0) }}</div>
                                        </div>
                                        <div>
                                            <p class="m-0 table-name">{{ admin.firstName + ' ' + admin.lastName }}</p>
                                            <small class="text-muted table-email">{{ admin.email }} </small>
                                        </div>
                                    </div>
                                </td>
                                <td class="table-phone">{{ formatPhone(admin.phone) }}</td>
                                <td class="table-status">{{ admin.isActive ? 'Active' : 'Deactivated' }}</td>
                                <td class="text-right">
                                    <div class="dropdown">
                                        <button class="btn btn-white border-0 action-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="far fa-ellipsis-v fa-lg"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a class="dropdown-item"
                                                   id="dropdown-item-edit"
                                                   data-bs-toggle="modal"
                                                   data-bs-target="#new-admin-user"
                                                   href="#"
                                                   @click.prevent="onEditUserClicked(admin)"><i class="fa-light fa-pencil fa-fw"></i> Edit</a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item dropdown-item-send-password-reset"
                                                   v-if="admin.isActive"
                                                   href="#"
                                                   @click.prevent="onSendPasswordResetClicked(admin.email)"><i class="fa-light fa-lock fa-fw"></i> Send Password Reset</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card" v-if="filteredSuperAdminUsers && filteredSuperAdminUsers.length === 0">
                    <div class="my-4 text-center">
                        <h3 class="mb-3"><i class="fa-regular fa-search"></i></h3>
                        <p>No users found.</p>
                    </div>
                </div>
            </div>

        </div>
        <UserFooter />
    </div>

    <!-- New/Edit Admin User Modal -->
    <div class="modal fade"
         id="new-admin-user"
         tabindex="-1"
         data-bs-backdrop="static"
         aria-labelledby="new-admin-user"
         aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">{{ isEditing ? 'Edit' : 'New'}} Admin User</h1>
                    <button type="button"
                            ref="closeModalButton"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            @click="isEditing = false"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="adminUserFirstName" class="form-label">First Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="adminUserFirstName"
                                   placeholder="First Name"
                                   v-model="addOrEditAdminUser.firstName"
                                   v-bind:class="{'is-invalid' : !validFirstName() && firstNameBlured}" v-on:blur="firstNameBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="adminUserLastName" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="adminUserLastName"
                                   placeholder="Last Name"
                                   v-model="addOrEditAdminUser.lastName"
                                   v-bind:class="{'is-invalid' : !validLastName() && lastNameBlured}" v-on:blur="lastNameBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md mb-3">
                            <label for="adminUserEmail" class="form-label">Email Address<sup class="text-danger">*</sup></label>
                            <input type="email"
                                   class="form-control"
                                   id="adminUserEmail"
                                   placeholder="Email Address"
                                   v-model="addOrEditAdminUser.email"
                                   v-bind:class="{'is-invalid' : !validEmail() && emailBlured}" v-on:blur="emailBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must contain a valid email.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="adminUserPhone" class="form-label">Phone<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="adminUserPhone"
                                   placeholder="Phone"
                                   v-model="addOrEditAdminUser.phone"
                                   v-bind:class="{'is-invalid' : !validPhone() && phoneBlured}" v-on:blur="phoneBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must contain a 10 digit phone number.
                            </div>
                        </div>
                    </div>
                    <div class="col-md mb-3">
                        <label for="userStatus" class="form-label d-block">Status</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input"
                                   type="checkbox"
                                   role="switch"
                                   id="flexSwitchCheckChecked"
                                   v-model="addOrEditAdminUser.isActive"
                                   checked />
                            <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-outline-secondary btn-round me-2"
                            data-bs-dismiss="modal"
                            @click="isEditing = false">
                        Cancel
                    </button>
                    <button type="button"
                            id="btn-submit"
                            class="btn btn-secondary btn-round"
                            @click="onSubmitClicked()">
                        Save User
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import axios from 'axios'
    import { CustomRegex } from '@/assets/CustomRegex'
    import { MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { SuperAdminUserDto } from '@/assets/types'
    import AdminMenu from '@/components/AdminMenu.vue'
    import UserFooter from '@/components/UserFooter.vue'
    import UserProfileDropdown from '@/components/UserProfileDropdown.vue'

    import { computed, ref, onMounted } from 'vue'

    const emptyAdminUser: Readonly<SuperAdminUserDto> = {
        id: null,
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        isActive: true,
        isSuperAdmin: true
    }

    const addOrEditAdminUser = ref<SuperAdminUserDto>(JSON.parse(JSON.stringify(emptyAdminUser)))
    const closeModalButton = ref<HTMLButtonElement>()
    const isEditing = ref(false)
    const isLoading = ref(false)
    const isSnackbarOpen = ref(false)
    const searchValue = ref('')
    const searchValueFilter = ref('')
    const selectedStatus = ref('Active')
    const superAdminUsers = ref(Array<SuperAdminUserDto>())
    const snackbarMessage = ref('')
    const statusOptions = ref(['All', 'Active', 'Deactivated'])

    const emailBlured = ref(false)
    const firstNameBlured = ref(false)
    const lastNameBlured = ref(false)
    const phoneBlured = ref(false)

    onMounted(async () => {
        await loadSuperAdmins()
    })

    const loadSuperAdmins = (async () => {
        try {
            superAdminUsers.value = (await axios.get('/api/admin/super-admins')).data
        } catch (error) {
            showSnackbar('Failed to retrieve super admins. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const onSearchClicked = (() => {
        searchValueFilter.value = searchValue.value
    })

    const onSendPasswordResetClicked = (async (email: string) => {
        try {
            isLoading.value = true
            await axios.post('/api/user/password-reset-request', {
                email: email
            })
            showSnackbar('Password reset email has been sent.')
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const onSubmitClicked = (async () => {
        try {
            if (!validate()) {
                return
            }
            addOrEditAdminUser.value.phone = addOrEditAdminUser.value.phone.replace(/\D/g, '')
            if (isEditing.value) {
                isLoading.value = true
                await axios.put('/api/admin/super-admin', addOrEditAdminUser.value)
                showSnackbar('Admin user was updated.')
            } else {
                isLoading.value = true
                await axios.post('/api/admin/super-admin', addOrEditAdminUser.value)
                showSnackbar('Admin user was added and welcome email was sent.')
            }
            closeModalButton.value?.click()
            await loadSuperAdmins()
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const validate = (() => {
        firstNameBlured.value = true
        lastNameBlured.value = true
        emailBlured.value = true
        phoneBlured.value = true
        return validFirstName() && validLastName() && validEmail() && validPhone()
    })

    const validFirstName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(addOrEditAdminUser.value.firstName.toLowerCase())) {
            return true
        }
    })

    const validLastName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(addOrEditAdminUser.value.lastName.toLowerCase())) {
            return true
        }
    })

    const validEmail = (() => {
        let regex = CustomRegex.isEmailAddress
        if (regex.test(addOrEditAdminUser.value.email.toLowerCase())) {
            return true
        }
    })

    const validPhone = (() => {
        let regex = CustomRegex.isPhoneNumber
        if (regex.test(addOrEditAdminUser.value.phone.toLowerCase())) {
            return true
        }
    })

    const formatPhone = ((phone: string) => {
        return phone.replace(CustomRegex.matchPhoneNumber, '$1-$2-$3')
    })

    const onNewUserClicked = (() => {
        resetValidation()
        isEditing.value = false
    })

    const resetValidation = (() => {
        firstNameBlured.value = false
        lastNameBlured.value = false
        emailBlured.value = false
        phoneBlured.value = false
        addOrEditAdminUser.value = JSON.parse(JSON.stringify(emptyAdminUser))
    })

    const onEditUserClicked = ((admin: SuperAdminUserDto) => {
        addOrEditAdminUser.value = JSON.parse(JSON.stringify(admin))
        isEditing.value = true
    })

    const filteredSuperAdminUsers = computed(() => {
        let result = superAdminUsers.value

        if (result && selectedStatus.value === 'Active') {
            result = result.filter((user) => user.isActive === true)
        } else if (result && selectedStatus.value === 'Deactivated') {
            result = result.filter((user) => user.isActive === false)
        }

        if (searchValueFilter.value !== '' && result.length > 0) {
            result = result.filter((user) =>
                user.email.toLowerCase().includes(searchValueFilter.value.toLowerCase()) ||
                user.firstName.toLowerCase().includes(searchValueFilter.value.toLowerCase()) ||
                user.lastName.toLowerCase().includes(searchValueFilter.value.toLowerCase()) ||
                `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(searchValueFilter.value.toLowerCase()) ||
                user.phone.includes(searchValueFilter.value)
            )
        }

        return result
    })

    const showSnackbar = ((message: string) => {
        snackbarMessage.value = message
        isSnackbarOpen.value = true
    })
</script>