<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />
    <div class="pre-loader" v-if="isLoading"></div>

    <div class="site-container">
        <UserMenu />
        <div class="admin-page-content">
            <ImpersonateBanner />
            <div class="dashboard-wrapper">
                <div class="dashboard-filters">
                    <div class="p-3">
                        <h5 class="text-strong"><i class="far fa-filter"></i> Filters</h5>
                    </div>
                    <div class="section-header"><label>Date Range</label></div>
                    <div class="p-3">
                        <div class="row align-items-center gx-2">
                            <div class="col">
                                <MbscDatepicker theme="ios"
                                                themeVariant="light"
                                                v-model="startDate"
                                                :max="maxStartDate"
                                                @change="onDateChanged" />
                            </div>
                            <div class="col-auto">to</div>
                            <div class="col">
                                <MbscDatepicker theme="ios"
                                                themeVariant="light"
                                                v-model="endDate"
                                                :max="maxEndDate"
                                                @change="onDateChanged" />
                            </div>
                            <div class="col-auto">
                                <button type="button"
                                        class="btn btn-sm btn-secondary text-600 hvr-grow"
                                        id="btn-apply"
                                        @click="onApplyClicked()">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="section-header"><label>Product</label></div>
                    <div class="p-3">
                        <div v-if="productsWithViolationsIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                            <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                            <p class="opacity-50 mb-0 mt-2 small">Loading Product...</p>
                        </div>
                        <div v-if="!productsWithViolationsIsLoading">
                            <MbscSelect theme="ios"
                                        themeVariant="light"
                                        :data="mappedProductsWithViolations"
                                        v-model="selectedProduct"
                                        :filter="true"
                                        @change="onProductSelected"></MbscSelect>
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required.
                            </div>
                        </div>
                    </div>
                    <div class="section-header"><label>Sellers</label></div>
                    <div class="filter-seller-list">
                        <p class="text-muted small m-3"><i class="fal fa-arrow-up"></i> Select an individual product to filter by seller</p>
                    </div>
                </div>

                <div class="dashboard-content">
                    <div class="container">
                        <div class="row align-items-center justify-content-between mb-5">
                            <div class="col">
                                <h4 class="mt-4 mb-0 text-strong"><i class="fal fa-tachometer"></i> Products Dashboard</h4>
                            </div>
                            <div class="col-auto">
                                <UserProfileDropdown />
                            </div>
                        </div>
                        <!--<div class="card p-3 mb-4">
                            <div v-if="chartDataIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                                <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                                <p class="opacity-50 mb-0 mt-2 small">Loading Chart...</p>
                            </div>
                            <div v-if="!chartDataIsLoading">
                                <h6 class="text-800 mb-3" id="h6-violations-date-range">Violation History {{ formattedAppliedStartDate }} - {{ formattedAppliedEndDate }}</h6>
                                <Line id="product-violations-chart" :data="chartData" :options="chartOptions" />
                            </div>
                        </div>-->
                        <div class="row mb-4">
                            <div class="col-md mb-3 mb-md-0">
                                <div class="card stat-card">
                                    <div v-if="totalViolationsIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                                        <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                                        <p class="opacity-50 mb-0 mt-2 small">Loading Totals...</p>
                                    </div>
                                    <div v-if="!totalViolationsIsLoading">
                                        <h4 class="m-0 text-800"
                                            id="h4-total-violations">
                                            {{ totalViolationsDto.totalViolations }}
                                        </h4>
                                        <label>Total Violations</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card stat-card">
                                    <div v-if="totalViolationsIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                                        <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                                        <p class="opacity-50 mb-0 mt-2 small">Loading Totals...</p>
                                    </div>
                                    <div v-if="!totalViolationsIsLoading">
                                        <h4 class="m-0 text-800"
                                            id="h4-average-violation">
                                            -${{ totalViolationsDto.averageViolation }}
                                        </h4>
                                        <label>Avg Violation</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div v-if="dataTableIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                                <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                                <p class="opacity-50 mb-0 mt-2 small">Loading History...</p>
                            </div>
                            <div class="p-3 d-flex align-items-center justify-content-between">
                                <h6 class="text-800 m-0">Violations By Product</h6>
                                <button type="button"
                                        class="btn btn-outline-secondary btn-sm px-3 btn-round"
                                        id="btn-export"
                                        @click="onExportClicked()">
                                    <i class="fal fa-file-export"></i> Export
                                </button>
                            </div>

                            <DataTable :columns="tableColumns"
                                       :options="tableOptions"
                                       class="table table-hover align-middle m-0"
                                       width="100%"
                                       ref="table">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Current MAP</th>
                                        <th>Lowest Price</th>
                                        <th>Violations</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <UserFooter />
    </div>

</template>

<script lang="ts" setup>

    import { ProductDto } from '@/assets/types'
    import ImpersonateBanner from '@/components/ImpersonateBanner.vue'
    import UserMenu from '@/components/UserMenu.vue'
    import UserFooter from '@/components/UserFooter.vue'
    import UserProfileDropdown from '@/components/UserProfileDropdown.vue'

    import { computed, onMounted, ref } from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    import axios from 'axios'
    import { Tooltip } from 'bootstrap'
    //import 'chartjs-adapter-date-fns'
    //import { Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, TimeScale, Title, Tooltip } from 'chart.js'
    import DataTable from 'datatables.net-vue3'
    import DataTablesCore from 'datatables.net-bs5'
    import { MbscDatepicker, MbscSelect, MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
/*    import { Line } from 'vue-chartjs'*/

    //ChartJS.register(Legend, LinearScale, LineElement, PointElement, TimeScale, Title, Tooltip)
    DataTable.use(DataTablesCore)

    const route = useRoute()
    const router = useRouter()
    const table = ref<typeof DataTable>(DataTable)

    const isLoading = ref(false)
    const chartDataIsLoading = ref(false)
    const dataTableIsLoading = ref(false)
    const productsWithViolationsIsLoading = ref(false)
    const totalViolationsIsLoading = ref(false)

/*    const chartDataDto = ref([])*/
    const productsWithViolationsDto = ref([])
    const selectedProduct = ref()
    const totalViolationsDto = ref({})
    const isSnackbarOpen = ref(false)
    const snackbarMessage = ref('')

    const appliedStartDate = ref(new Date())
    const appliedEndDate = ref(new Date())
    const startDate = ref(route.query.startDate != null ? new Date(route.query.startDate + '') : new Date())
    const endDate = ref(route.query.endDate != null ? new Date(route.query.endDate + '') : new Date())
    if (route.query.startDate == null) startDate.value.setDate(startDate.value.getDate() - 31)
    if (route.query.endDate == null) endDate.value.setDate(endDate.value.getDate())
    appliedStartDate.value = new Date(startDate.value)
    appliedEndDate.value = new Date(endDate.value)

    let dt: any
    onMounted(async () => {
        dt = table.value.dt
        dt.on('click', '#favorite-icon', function (e: any) {
            let id = dt.row(e.target.closest('tr')).data().id
            let favorite = !(dt.row(e.target.closest('tr')).data().favorite)
            onFavoriteIconClicked(id, favorite)
        })
        dt.on('draw.dt', function () {
            dt.rows().every(function (rowIdx: any) {
                let row = dt.row(rowIdx).node()

                let catalogStatusTooltip = row.querySelector('#tooltip-catalog-status')
                if (catalogStatusTooltip) new Tooltip(catalogStatusTooltip)
                
                let enforcedTooltip = row.querySelector('#tooltip-enforced')
                if (enforcedTooltip) new Tooltip(enforcedTooltip)
            })
        })
        try {
            await loadData()
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onFavoriteIconClicked = (async (id: number, favorite: boolean) => {
        try {
            let editProduct: ProductDto = {
                id: id,
                companyId: null,
                asin: '',
                sku: '',
                map: null,
                catalogStatus: '',
                isFavorite: favorite,
                title: '',
                brandName: '',
                imageUrl: ''
            }
            await axios.put('/api/product', editProduct)
            dt.ajax.reload(null, false)

            if (favorite) {
                showSnackbar('Product was favorited.')
            } else {
                showSnackbar('Product was unfavorited.')
            }
        } catch {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onDateChanged = (() => {
        if (startDate.value >= endDate.value) {
            const newStartDate = new Date(formattedEndDate.value)
            newStartDate.setDate(newStartDate.getDate() - 1)
            startDate.value = new Date(newStartDate)
        }
    })

    const onApplyClicked = (async () => {
        appliedStartDate.value = new Date(startDate.value)
        appliedEndDate.value = new Date(endDate.value)
        reloadTable()
        await loadData()
    })

    const loadData = (async () => {
        chartDataIsLoading.value = true
        productsWithViolationsIsLoading.value = true
        totalViolationsIsLoading.value = true
        
        try {
            await Promise.all([
                //axios.get('/api/product/dashboard/violations-chart-data/?startDate=' + formattedStartDate.value + '&endDate=' + formattedEndDate.value)
                //    .then((chartData) => {
                //        chartDataDto.value = chartData.data
                //        chartDataIsLoading.value = false
                //    }),
                axios.get('/api/product/dashboard/products-with-violations/?startDate=' + formattedStartDate.value + '&endDate=' + formattedEndDate.value)
                    .then((productsWithViolations) => {
                        productsWithViolationsDto.value = productsWithViolations.data
                        productsWithViolationsIsLoading.value = false
                    }),
                axios.get('/api/product/dashboard/total-violations/?startDate=' + formattedStartDate.value + '&endDate=' + formattedEndDate.value)
                    .then((totalViolations) => {
                        totalViolationsDto.value = totalViolations.data
                        totalViolationsIsLoading.value = false
                    })
            ])
        } catch {
            showSnackbar('An error occurred. Please contact support.')
            chartDataIsLoading.value = false
            productsWithViolationsIsLoading.value = false
            totalViolationsIsLoading.value = false
        }
    })

    const onProductSelected = (() => {
        if (selectedProduct.value) {
            router.push(getDashboardSellersViewRouteLocation(selectedProduct.value))
        }
    })

    const getDashboardSellersViewRouteLocation = ((productId: number) => {
        return { name: 'products-dashboard-sellers-view', query: { productId: productId, startDate: formatDate(appliedStartDate.value), endDate: formatDate(appliedEndDate.value) } }
    })

    const onExportClicked = (async () => {
        const response = await axios({
            url: '/api/product/dashboard/violations-by-product/export?startDate=' + formattedAppliedStartDate.value + '&endDate=' + formattedAppliedEndDate.value,
            method: 'GET',
            responseType: 'blob',
        })
        if (response && response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]))

            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Violations_By_Product_${formattedAppliedStartDate.value}_to_${formattedAppliedEndDate.value}.csv`)
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
        }
    })

    const formattedAppliedStartDate = computed(() => {
        return formatDate(appliedStartDate.value)
    })

    const formattedAppliedEndDate = computed(() => {
        return formatDate(appliedEndDate.value)
    })

    const formattedStartDate = computed(() => {
        return formatDate(startDate.value)
    })

    const formattedEndDate = computed(() => {
        return formatDate(endDate.value)
    })

    const formatDate = ((date: Date) => {
        let year = date.getFullYear()
        let month: any = date.getMonth() + 1
        let day: any = date.getDate()

        if (month < 10) month = '0' + month
        if (day < 10) day = '0' + day

        return `${month}/${day}/${year}`
    })

    const maxStartDate = computed(() => {
        const maxStartDt = new Date()
        maxStartDt.setDate(maxEndDate.value.getDate() - 1)
        return maxStartDt
    })

    const maxEndDate = computed(() => {
        const today = new Date()
        return today
    })

    const mappedProductsWithViolations = computed(() => {
        return Array.isArray(productsWithViolationsDto.value) ? productsWithViolationsDto.value
            .map((product: any) => ({ text: `${product.asin} - ${product.title}`, value: product.id })) : []
    })

    const showSnackbar = ((msg: string) => {
        snackbarMessage.value = msg
        isSnackbarOpen.value = true
    })

    //const chartData = computed(() => {
    //    return {
    //        type: 'line',
    //        datasets: [{
    //            data: chartDataDto.value.length > 0 ? chartDataDto.value.filter((v, i, a) => a.findIndex(v2 => (JSON.stringify(v2) === JSON.stringify(v))) === i) : [],
    //            label: 'Violations',
    //            borderColor: '#FF0030',
    //            backgroundColor: '#FF0030',
    //            pointRadius: 4,
    //            fill: false,
    //            lineTension: 0.4,
    //        }]
    //    }
    //})

    //const chartOptions = computed(() => {
    //    return {
    //        responsive: true,
    //        scales: {
    //            x: {
    //                type: 'time',
    //                time: {
    //                    unit: chartTimeUnit.value
    //                },
    //                grid: {
    //                    display: false
    //                }
    //            },
    //            y: {
    //                beginAtZero: true,
    //                grid: {
    //                    display: false
    //                },
    //                ticks: {
    //                    beginAtZero: true,
    //                    callback: function (value:any) { if (value % 1 === 0) { return value } }
    //                }
    //            }
    //        },
    //        plugins: {
    //            legend: {
    //                display: false
    //            }
    //        }
    //    }
    //})

    //const chartTimeUnit = computed(() => {
    //    let differenceInTime = appliedEndDate.value.getTime() - appliedStartDate.value.getTime()
    //    let differenceInDays = (Math.round(differenceInTime / (1000 * 3600 * 24)))
    //    let timeUnit = ''

    //    if (differenceInDays <= 14) {
    //        timeUnit = 'day'
    //    } else if (differenceInDays <= 90) {
    //        timeUnit = 'week'
    //    } else if (differenceInDays <= 366) {
    //        timeUnit = 'month'
    //    } else {
    //        timeUnit = 'year'
    //    }

    //    return timeUnit
    //})

    const tableColumns = ref([
        {
            data: 'title',
            render: function (data: any, type: any, row: any) {
                let favoriteIcon = row.favorite ? '<a id="favorite-icon" class="favorite-icon" role="button" href="javascript:void(0)"><i class="fas fa-star"></i></a>'
                    : '<a id="favorite-icon" class="favorite-icon" role="button" href="javascript:void(0)"><i class="far fa-star"></i></a>'

                return `<div class="row align-items-center">
                    <div class="col-auto">
                        ${favoriteIcon}
                    </div>
                    <div class="col-auto">
                        <img src="${row.imageUrl}" alt="product" class="product-img" />
                    </div>
                    <div class="col">
                        <p class="mb-0"><small>${row.asin}</small></p>
                        <p class="text-body product-title"><a style="color: inherit;" href="javascript:void(0)" onclick="window.open('https://www.amazon.com/dp/${row.asin}', '_blank')">${data}</a></p>
                    </div>
                </div>`
            }
        },
        {
            data: 'currentMap',
            render: function (data: any) {
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data)
            }
        },
        {
            data: 'lowestPrice',
            render: function (data: any, type: any, row: any) {
                const formattedCurrency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data)
                return row.lowestPrice < row.currentMap ? `<span style="color: #FF0030">${formattedCurrency}</span>` : formattedCurrency
            }
        },
        {
            data: 'violations',
            render: function (data: any, type: any, row: any) {
                return `<p class="text-800 text-body"><a style="color: inherit;" href="${router.resolve(getDashboardSellersViewRouteLocation(row.id)).href}">${data} violations</a></p>
                        <small>${formatDate(new Date(row.latestViolationDate))}</small>`
            }
        },
        {
            data: 'isEnforced',
            render: function (data: any) {
                return data == true ? `<div class="d-flex">
						    <img id="tooltip-enforced" class="shield-icon" style="max-width: unset;" alt="Enforced" data-bs-toggle="tooltip" data-bs-title="Is Enforced" src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDAgMjUwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmMDAzMDtzdHJva2Utd2lkdGg6MHB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMDAsNjMuOTd2LTEzLjk3TDEwMCwwbC0uMTQuMDctNi43NywzLjM4TDAsNTBoMHYzMy43MmMwLDEuNTYuMDIsMy4xMy4wNiw0LjY5LjA4LDMuMTIuMjUsNi4yMy41LDkuMzIuMTMsMS41NS4yNywzLjA5LjQ0LDQuNjMuMTksMS43Ny40MiwzLjUzLjY2LDUuMjkuMzksMi44My44NSw1LjY1LDEuMzgsOC40NS4yOSwxLjUxLjU5LDMuMDIuOTIsNC41Mi45MSw0LjE3LDEuOTgsOC4zLDMuMTksMTIuMzguMjQuNzkuNDgsMS41OS43MywyLjM4LDEuMSwzLjUyLDIuMzEsNy4wMSwzLjYzLDEwLjQ1LjM3Ljk3Ljc2LDEuOTQsMS4xNSwyLjkuNjQsMS41OCwxLjMsMy4xNSwxLjk5LDQuNzIuNjksMS41NiwxLjM5LDMuMTEsMi4xMiw0LjY2LDEuODQsMy44OCwzLjgyLDcuNjgsNS45NCwxMS40Mi43MSwxLjI0LDEuNDMsMi40OCwyLjE3LDMuNzEsMi4yOSwzLjgxLDQuNzQsNy41NCw3LjMzLDExLjE4LDcuNzYsMTAuOTEsMTYuODMsMjAuOTgsMjcuMDksMjkuOTZsMy42MiwzLjE3LDE4LjU1LDE2LjIzLDE4LjU1LDE2LjIzLDEwLjE3LTguODktMTAuMTctNS4wN3YtMjUuMzVsMjguNjIsMTQuMjcsMTAuMTctOC45LTM4Ljc5LTE5LjM0di0yNS4zNWw1NS45NSwyNy44OWMyLjg2LTMuMiw1LjU4LTYuNSw4LjE4LTkuODlsLTY0LjEzLTMxLjk3di0yNS4zNWw3Ni44MiwzOC4zYzIuMDgtMy42MSw0LjA0LTcuMyw1Ljg1LTExLjA1bC04Mi42OC00MS4yMnYtMjUuMzVsOTEuMjIsNDUuNDhjMS4zMy00LjAyLDIuNS04LjA5LDMuNTQtMTIuMmwtOTQuNzYtNDcuMjV2LTI1LjM1bDk4Ljk1LDQ5LjMzYy40OS00LjQ3LjgxLTguOTcuOTUtMTMuNDlsLTk5LjktNDkuODFWMTQuMTFsMTAwLDQ5Ljg2WiIvPjwvc3ZnPg=='/>
						</div>` : `<div class="d-flex">
                            <img id="tooltip-enforced" class="shield-icon" style="max-width: unset;" alt="Enforced" data-bs-toggle="tooltip" data-bs-title="Not Enforced" src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDAgMjUwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2QxZDdkYjtzdHJva2Utd2lkdGg6MHB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMDAsNjMuOTd2LTEzLjk3TDEwMCwwbC0uMTQuMDctNi43NywzLjM4TDAsNTBoMHYzMy43MmMwLDEuNTYuMDIsMy4xMy4wNiw0LjY5LjA4LDMuMTIuMjUsNi4yMy41LDkuMzIuMTMsMS41NS4yNywzLjA5LjQ0LDQuNjMuMTksMS43Ny40MiwzLjUzLjY2LDUuMjkuMzksMi44My44NSw1LjY1LDEuMzgsOC40NS4yOSwxLjUxLjU5LDMuMDIuOTIsNC41Mi45MSw0LjE3LDEuOTgsOC4zLDMuMTksMTIuMzguMjQuNzkuNDgsMS41OS43MywyLjM4LDEuMSwzLjUyLDIuMzEsNy4wMSwzLjYzLDEwLjQ1LjM3Ljk3Ljc2LDEuOTQsMS4xNSwyLjkuNjQsMS41OCwxLjMsMy4xNSwxLjk5LDQuNzIuNjksMS41NiwxLjM5LDMuMTEsMi4xMiw0LjY2LDEuODQsMy44OCwzLjgyLDcuNjgsNS45NCwxMS40Mi43MSwxLjI0LDEuNDMsMi40OCwyLjE3LDMuNzEsMi4yOSwzLjgxLDQuNzQsNy41NCw3LjMzLDExLjE4LDcuNzYsMTAuOTEsMTYuODMsMjAuOTgsMjcuMDksMjkuOTZsMy42MiwzLjE3LDE4LjU1LDE2LjIzLDE4LjU1LDE2LjIzLDEwLjE3LTguODktMTAuMTctNS4wN3YtMjUuMzVsMjguNjIsMTQuMjcsMTAuMTctOC45LTM4Ljc5LTE5LjM0di0yNS4zNWw1NS45NSwyNy44OWMyLjg2LTMuMiw1LjU4LTYuNSw4LjE4LTkuODlsLTY0LjEzLTMxLjk3di0yNS4zNWw3Ni44MiwzOC4zYzIuMDgtMy42MSw0LjA0LTcuMyw1Ljg1LTExLjA1bC04Mi42OC00MS4yMnYtMjUuMzVsOTEuMjIsNDUuNDhjMS4zMy00LjAyLDIuNS04LjA5LDMuNTQtMTIuMmwtOTQuNzYtNDcuMjV2LTI1LjM1bDk4Ljk1LDQ5LjMzYy40OS00LjQ3LjgxLTguOTcuOTUtMTMuNDlsLTk5LjktNDkuODFWMTQuMTFsMTAwLDQ5Ljg2WiIvPjwvc3ZnPg=='/>
                        </div>`
            }
        },
        {
            data: 'catalogStatus',
            render: function (data: any) {
                return data == 'Active' ? `<div class="d-flex">
						    <span id="tooltip-catalog-status" data-bs-toggle="tooltip" data-bs-title="Real-Time Price Updates"><i class="far fa-stopwatch fa-lg"></i></span>
						</div>` : ''                    
             }
        },
    ])

    const tableOptions = ref({
        responsive: true,
        dom: 'rtipl',
        processing: true,
        serverSide: true,
        ajax: {
            url: `/api/product/dashboard/violations-by-product/page`,
            type: 'POST',
            contentType: 'application/json',
            data: (d: any) => getPostData(d),
            dataSrc: (json: any) => getDataSrc(json),
            error: () => showSnackbar('An error occurred when loading the table. Please contact support.'),
            columns: tableColumns.value
        },
        language: {
            lengthMenu: '_MENU_'
        },
        lengthMenu: [10, 50, 100, 1000],
        select: {
            style: 'multi',
            selector: 'td.select-checkbox'
        },
        preDrawCallback: () => dataTableIsLoading.value = true,
        drawCallback: () => dataTableIsLoading.value = false
    })

    const getPostData = ((d: any) => {
        const pageNumber = d.start / d.length + 1
        const pageSize = d.length
        let startDate = new Date(appliedStartDate.value)
        let endDate = new Date(appliedEndDate.value)
        return JSON.stringify({ PageIndex: pageNumber, PageSize: pageSize, StartDate: startDate, EndDate: endDate })
    })

    const getDataSrc = ((response: any) => {
        return response.productViolationList
    })

    const reloadTable = (() => {
        table.value.dt.ajax.reload()
    })

</script>