<template>
    <MbscSnackbar display="top"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />
    <div id="logged-in-user-profile">
        <div class="dropdown">
            <button class="btn btn-white border-0 d-flex justify-content-between align-items-center btn-sm"
                    id="dropdown-my-profile"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                <div class="d-flex align-items-center">
                    <div class="avatar avatar-sm me-2">
                        {{ editUserProfile.firstName && editUserProfile.lastName && editUserProfile.firstName ? editUserProfile?.firstName.charAt(0) + editUserProfile?.lastName.charAt(0) : '' }}
                    </div>
                    <span class="me-3 user-name">{{ editUserProfile?.firstName }} {{ editUserProfile?.lastName }}</span>
                </div>
                <i class="fal fa-chevron-down"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-end mt-2">
                <li>
                    <a class="dropdown-item"
                       id="dropdown-item-my-profile"
                       data-bs-toggle="modal"
                       data-bs-target="#my-profile"
                       href="#"><i class="fal fa-pencil"></i> Edit my profile</a>
                </li>
                <li>
                    <a class="dropdown-item"
                       id="signout"
                       href="#"
                       @click.prevent="onSignOutClicked()"><i class="fal fa-sign-out-alt"></i> Sign Out</a>
                </li>
            </ul>
        </div>
        <!-- My Profile Modal -->
        <div class="modal fade"
             id="my-profile"
             data-bs-backdrop="static"
             tabindex="-1"
             aria-labelledby="my-profile"
             aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-3">My Profile</h1>
                        <button type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                ref="closeProfileModalButton"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md mb-3">
                                <label for="userFirstName" class="form-label">First Name<sup class="text-danger">*</sup></label>
                                <input type="text"
                                       class="form-control"
                                       id="userFirstName"
                                       placeholder="First Name"
                                       v-model="editUserProfile.firstName"
                                       v-bind:class="{'is-invalid' : !validFirstName() && firstNameBlured}"
                                       v-on:blur="firstNameBlured = true"
                                       required>
                                <div class="invalid-feedback">
                                    <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                                </div>
                            </div>
                            <div class="col-md mb-3">
                                <label for="userLastName" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                                <input type="text"
                                       class="form-control"
                                       id="userLastName"
                                       placeholder="Last Name"
                                       v-model="editUserProfile.lastName"
                                       v-bind:class="{'is-invalid' : !validLastName() && lastNameBlured}"
                                       v-on:blur="lastNameBlured = true"
                                       required>
                                <div class="invalid-feedback">
                                    <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mb-3">
                                <label for="userEmail" class="form-label">Email Address<sup class="text-danger">*</sup></label>
                                <input type="email"
                                       class="form-control"
                                       id="userEmail"
                                       placeholder="Email Address"
                                       v-model="editUserProfile.email"
                                       v-bind:class="{'is-invalid' : !validEmail() && emailBlured}"
                                       v-on:blur="emailBlured = true"
                                       required>
                                <div class="invalid-feedback">
                                    <i class="fas fa-exclamation-circle"></i> This field is required and must contain a valid email.
                                </div>
                            </div>
                            <div class="col-md mb-3">
                                <label for="userPhone" class="form-label">Phone<sup class="text-danger">*</sup></label>
                                <input type="text"
                                       class="form-control"
                                       id="userPhone"
                                       placeholder="Phone"
                                       v-model="editUserProfile.phone"
                                       v-bind:class="{'is-invalid' : !validPhone() && phoneBlured}"
                                       v-on:blur="phoneBlured = true"
                                       required>
                                <div class="invalid-feedback">
                                    <i class="fas fa-exclamation-circle"></i> This field is required and must contain a 10 digit phone number.
                                </div>
                            </div>

                        </div>
                        <i>If you update your email address, you will be signed out.</i>
                        <br />
                        <a href="#"
                           id="reset-user-password"
                           class="text-body"
                           @click.prevent="onSendPasswordResetClicked()">Send me an email to reset my password</a>
                    </div>
                    <div class="modal-footer">
                        <button type="button"
                                class="btn btn-outline-secondary btn-round me-2" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button"
                                id="btn-submit-user-profile"
                                class="btn btn-secondary btn-round"
                                @click="onSubmitClicked()">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import axios from 'axios'
    import { CustomRegex } from '@/assets/CustomRegex'
    import { MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { UserProfileDto } from '@/assets/types'
    import { onMounted, ref } from 'vue'
    import { useCookie } from 'vue-cookie-next'
    import { useRouter } from 'vue-router'

    const emptyUserProfile: Readonly<UserProfileDto> = {
        userId: null,
        firstName: ' ',
        lastName: ' ',
        phone: '',
        email: ''
    }

    const cookies = useCookie()
    const router = useRouter()
    const closeProfileModalButton = ref<HTMLButtonElement>()
    const editUserProfile = ref<UserProfileDto>(JSON.parse(JSON.stringify(emptyUserProfile)))
    const isSnackbarOpen = ref(false)
    const originalUserProfileEmail = ref('')
    const snackbarMessage = ref('')

    const emailBlured = ref(false)
    const firstNameBlured = ref(false)
    const lastNameBlured = ref(false)
    const phoneBlured = ref(false)

    onMounted(async () => {
        try {
            let result = (await axios.get('/api/user/profile')).data
            if (result) {
                editUserProfile.value = result
                originalUserProfileEmail.value = result.email
            }
        } catch (error) {
            showSnackbar('Failed to retrieve user profile. Please contact support.')
        }
    })

    const onSubmitClicked = (async () => {
        try {
            if (!validate()) {
                return
            }
            editUserProfile.value.phone = editUserProfile.value.phone.replace(CustomRegex.matchNonDigit, '')
            await axios.put('/api/user/profile', editUserProfile.value)
            showSnackbar('User profile was updated.')
            closeProfileModalButton.value?.click()
            if (editUserProfile.value.email !== originalUserProfileEmail.value) {
                onSignOutClicked()
            }
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const validate = (() => {
        firstNameBlured.value = true
        lastNameBlured.value = true
        emailBlured.value = true
        phoneBlured.value = true
        return validFirstName() && validLastName() && validEmail() && validPhone()
    })

    const validFirstName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(editUserProfile.value && editUserProfile.value.firstName && editUserProfile.value.firstName.toLowerCase())) {
            return true
        }
    })

    const validLastName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(editUserProfile.value && editUserProfile.value.lastName && editUserProfile.value.lastName.toLowerCase())) {
            return true
        }
    })

    const validEmail = (() => {
        let regex = CustomRegex.isEmailAddress
        if (regex.test(editUserProfile.value && editUserProfile.value.email && editUserProfile.value.email.toLowerCase())) {
            return true
        }
    })

    const validPhone = (() => {
        let regex = CustomRegex.isPhoneNumber
        if (regex.test(editUserProfile.value && editUserProfile.value.phone && editUserProfile.value.phone.toLowerCase())) {
            return true
        }
    })

    const onSignOutClicked = (async () => {
        try {
            await axios.post('/api/user/logout')
            cookies.removeCookie('role:SuperAdmin')
            router.push({ name: 'login' })
        } catch (error) {
            showSnackbar('Unable to logout.')
        }
    })

    const onSendPasswordResetClicked = (async () => {
        try {
            await axios.post('/api/user/password-reset-request', {
                email: editUserProfile.value.email
            })
            showSnackbar('Password reset email has been sent.')
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const showSnackbar = ((msg: string) => {
        snackbarMessage.value = msg
        isSnackbarOpen.value = true
    })

</script>
