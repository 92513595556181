<template>
    <nav id="admin-menu" :class="{'show': showMobileFriendlyMenu, 'menu-collapsed': showCollapsedMenu}" @click="showMobileFriendlyMenu = !showMobileFriendlyMenu">
        <a role="button" class="mobile-menu-btn"><i class="fal fa-bars fa-lg"></i></a>
        <div class="logo">
            <img src="@/assets/images/logo-white.svg" alt="MapAuthority logo" />
        </div>
        <ul class="admin-nav">
            <li>
                <router-link :to="{ name: 'products-dashboard-products-view' }">
                    <span>
                        <span class="icon"><i class="fal fa-tachometer"></i></span>
                        <span class="menu-text"> Products Dashboard</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'manage-products' }">
                    <span>
                        <span class="icon"><i class="fal fa-box"></i></span>
                        <span class="menu-text"> Manage Products</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'manage-users' }">
                    <span>
                        <span class="icon"><i class="fal fa-user"></i></span>
                        <span class="menu-text"> Users</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'manage-brands' }">
                    <span>
                        <span class="icon"><i class="fal fa-tag"></i></span>
                        <span class="menu-text"> Brands</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'manage-retailers' }">
                    <span>
                        <span class="icon"><i class="fal fa-shop"></i></span>
                        <span class="menu-text"> Retailers</span>
                    </span>
                </router-link>
            </li>

            <!--<li>
            <a href="#">
                <span>
                    <span class="icon"><i class="fal fa-cog"></i></span>
                    <span class="menu-text"> Settings</span>
                </span>
            </a>
        </li>-->
        </ul>
        <div class="menu-toggle" @click="expandOrCollapseMenu()">
            <a role="button">
                <i class="far fa-chevrons-right menu-expand-icon"></i>
                <i class="far fa-chevrons-left menu-collapse-icon"></i>
            </a>
        </div>
    </nav>
</template>


<script lang="ts" setup>

    import { ref } from 'vue'

    const showMobileFriendlyMenu = ref(false)
    const showCollapsedMenu = ref(false)

    const expandOrCollapseMenu = (() => {
        if (showCollapsedMenu.value) {
            showCollapsedMenu.value = false
            document.body.classList.remove('menu-collapsed')
        } else {
            showCollapsedMenu.value = true
            document.body.classList.add('menu-collapsed')
        }
    })

</script>