<template>
    <MbscAlert display="center"
               theme="ios"
               themeVariant="dark"
               :message="alertMessage"
               :isOpen="isAlertOpen"
               @close="onAlertClosed" />
    <div class="login">

        <div class="pre-loader" v-if="isLoading"></div>

        <div class="login-container">

            <div class="login-box">
                <img src="@/assets/images/logo-white.svg" alt="MapAuthority" class="logo" />

                <form class="forgot-password-form" @submit.prevent="onFormSubmit">
                    <h4 class="text-center mb-4">Forgot Password</h4>
                    <p class="text-center">Enter your email below and a reset password<br />link will be sent to you.</p>
                    <div class="row align-items-center mb-5">
                        <div class="col-auto login-icon"><i class="fal fa-user fa-2x"></i></div>
                        <div class="col">
                            <input type="email"
                                   class="form-control form-control-lg dark"
                                   placeholder="Email Address"
                                   v-model="email" />
                        </div>
                    </div>
                    <div class="d-grid mb-5">
                        <button type="submit"
                                class="btn btn-primary btn-lg mx-auto btn-round px-5 hvr-grow">
                            Reset Password
                        </button>
                    </div>
                    <div class="text-center">
                        <router-link class="login-back-link" :to="{ name: 'login' }"><i class="far fa-arrow-left"></i> back to login</router-link>
                    </div>
                </form>
            </div>

        </div>

        <LoginFooter />

    </div>
</template>

<script lang="ts" setup>
    import axios from 'axios'
    import LoginFooter from '@/components/LoginFooter.vue'
    import { MbscAlert } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { ref, onMounted } from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    const route = useRoute()
    const router = useRouter()

    const alertMessage = ref('')
    const alertRoute = ref('')
    const email = ref(route.query.email)
    const isAlertOpen = ref(false)
    const isLoading = ref(true)

    onMounted(() => {
        isLoading.value = false
    })

    const onAlertClosed = (() => {
        router.push({ name: alertRoute.value })
    })

    const onFormSubmit = (async () => {
        try {
            isLoading.value = true
            await axios.post('/api/user/password-reset-request', {
                email: email.value
            })
            showAlert('If the email you entered exists, you will recieve an email with a link to reset your password.', 'login')
        } catch (error) {
            showAlert('An error occurred. Please contact support.', 'forgot-password')
        } finally {
            isLoading.value = false
        }
    })

    const showAlert = ((message: string, routeName: string) => {
        alertMessage.value = message
        isAlertOpen.value = true
        alertRoute.value = routeName
    })
</script>