<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />
    <div class="pre-loader" v-if="isLoading"></div>

    <div class="site-container">
        <UserMenu />
        <div class="admin-page-content">
            <ImpersonateBanner />
            <div class="dashboard-wrapper">
                <div class="dashboard-filters">
                    <div class="p-3">
                        <h5 class="text-strong"><i class="far fa-filter"></i> Filters</h5>
                    </div>
                    <div class="section-header"><label>Date Range</label></div>
                    <div class="p-3">
                        <div class="row align-items-center gx-2">
                            <div class="col">
                                <MbscDatepicker theme="ios"
                                                themeVariant="light"
                                                v-model="startDate"
                                                :max="maxStartDate"
                                                @change="onDateChanged" />
                            </div>
                            <div class="col-auto">to</div>
                            <div class="col">
                                <MbscDatepicker theme="ios"
                                                themeVariant="light"
                                                v-model="endDate"
                                                :max="maxEndDate"
                                                @change="onDateChanged" />
                            </div>
                            <div class="col-auto">
                                <button type="button"
                                        class="btn btn-sm btn-secondary text-600 hvr-grow"
                                        id="btn-apply"
                                        @click="onApplyClicked()">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="section-header">
                        <label>Product</label>
                        <a class="body-text-link" href="#" @click.prevent="onClearButtonClicked"><i class="fal fa-times"></i> Clear</a>
                    </div>
                    <div class="p-3">
                        <div class="row align-items-center mb-3">
                            <div class="col-3">
                                <img :src="productDto.imageUrl" alt="product" class="d-block m-auto" />
                            </div>
                            <div class="col">
                                <p class="text-800 mb-0 line-height-normal"></p>
                                <p class="text-body product-title">
                                    <a style="color: inherit;" href="javascript:void(0)" @click="onProductTitleClicked()">{{ productDto.title }}</a>
                                </p>
                            </div>
                        </div>
                        <ul class="list-unstyled m-0 p-0 d-flex small">
                            <li class="me-3">
                                <label class="d-block">Brand</label>
                                <span>{{ productDto.brandName }}</span>
                            </li>
                            <li class="me-3">
                                <label class="d-block">ASIN</label>
                                <span>{{ productDto.asin }}</span>
                            </li>
                            <li class="me-3">
                                <label class="d-block">SKU</label>
                                <span>{{ productDto.sku }}</span>
                            </li>
                            <li>
                                <label class="d-block">MAP</label>
                                <span>{{ formatCurrency(productDto.map) }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="section-header"><label>Sellers</label></div>
                    <div class="filter-seller-list">
                        <div v-if="sellersWithViolationsIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                            <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                            <p class="opacity-50 mb-0 mt-2 small">Loading Sellers...</p>
                        </div>
                        <ul>
                            <li v-for="(item) in sellersWithViolationsDto" :key="item.sellerId">
                                <div class="form-check">
                                    <input class="form-check-input"
                                           type="radio"
                                           name="flexRadioDefault"
                                           :id="item.sellerId"
                                           :value="item.sellerId"
                                           v-model="selectedSellerId"
                                           @change="loadData">
                                    <label :for="item.sellerId">{{ item.sellerId == amazonSellerId ? amazonSellerName : item.sellerId }}</label>
                                </div>
                                <span class="small">
                                    <i class="fal fa-exclamation-triangle"></i> {{ item.totalViolations }} violations
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="dashboard-content">
                    <div class="container">
                        <div class="row align-items-center justify-content-between mb-5">
                            <div class="col">
                                <h4 class="mt-4 mb-0 text-strong">
                                <i class="fal fa-tachometer"></i> 
                                {{ trimToLength(productDto.title, 20) }} / <a v-if="selectedSellerId != amazonSellerId" :href="'https://www.amazon.com/sp?marketplaceID=ATVPDKIKX0DER&seller=' + selectedSellerId + '&ref_=dp_merchant_link'" target="_blank">{{ selectedSellerId }}</a><span v-else>{{ amazonSellerName }}</span></h4>
                            </div>
                            <div class="col-auto">
                                <div class="col-auto">
                                    <UserProfileDropdown />
                                </div>
                            </div>
                        </div>
                        <div class="card p-3 mb-4">
                            <div v-if="chartDataIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                                <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                                <p class="opacity-50 mb-0 mt-2 small">Loading Chart...</p>
                            </div>
                            <div v-if="!chartDataIsLoading">
                                <h6 class="text-800 mb-3" id="h6-violations-date-range">Price History {{ formattedAppliedStartDate }} - {{ formattedAppliedEndDate }}</h6>
                                <Line id="seller-violations-chart" :data="chartData" :options="chartOptions" />
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md mb-3 mb-md-0">
                                <div class="card stat-card">
                                    <div v-if="totalViolationsIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                                        <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                                        <p class="opacity-50 mb-0 mt-2 small">Loading Totals...</p>
                                    </div>
                                    <div v-if="!totalViolationsIsLoading">
                                        <h4 class="m-0 text-800">
                                            {{ totalViolationsDto.totalViolations }}
                                            <span style="float: right"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-title="The number of price changes that went below MAP during the selected date range."
                                                  v-tooltip><i class="far fa-circle-info fa-sm"></i></span>
                                        </h4>
                                        <label>Total Violations</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card stat-card">
                                    <div v-if="totalViolationsIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                                        <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                                        <p class="opacity-50 mb-0 mt-2 small">Loading Totals...</p>
                                    </div>
                                    <div v-if="!totalViolationsIsLoading">
                                        <h4 class="m-0 text-800">
                                            {{ totalViolationsDto.daysInViolation }}
                                            <span style="float: right"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-title="The number of days a price has gone below MAP during the selected date range."
                                                  v-tooltip><i class="far fa-circle-info fa-sm"></i></span>
                                        </h4>
                                        <label>Days in Violation </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card stat-card">
                                    <div v-if="totalViolationsIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                                        <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                                        <p class="opacity-50 mb-0 mt-2 small">Loading Totals...</p>
                                    </div>
                                    <div v-if="!totalViolationsIsLoading">
                                        <h4 class="m-0 text-800">-{{ formatCurrency(totalViolationsDto.averageViolation) }}</h4>
                                        <label>Avg Violation</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div v-if="dataTableIsLoading" class="d-flex justify-content-center text-center flex-column mt-3">
                                <i class="fa-duotone fa-spinner-third fa-2x fa-spin"></i>
                                <p class="opacity-50 mb-0 mt-2 small">Loading History...</p>
                            </div>
                            <div class="p-3 d-flex align-items-center justify-content-between">
                                <h6 class="text-800 m-0">Violation History</h6>
                                <button type="button"
                                        class="btn btn-outline-secondary btn-sm px-3 btn-round"
                                        id="btn-export"
                                        @click="onExportClicked()">
                                    <i class="fal fa-file-export"></i> Export
                                </button>
                            </div>

                            <DataTable :columns="tableColumns"
                                       :options="tableOptions"
                                       class="table table-hover align-middle m-0"
                                       width="100%"
                                       ref="table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>MAP</th>
                                        <th>Landed Price</th>
                                        <th>Corrected</th>
                                    </tr>
                                </thead>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <UserFooter />
    </div>
</template>

<script lang="ts" setup>

    import ImpersonateBanner from '@/components/ImpersonateBanner.vue'
    import UserMenu from '@/components/UserMenu.vue'
    import UserFooter from '@/components/UserFooter.vue'
    import UserProfileDropdown from '@/components/UserProfileDropdown.vue'

    import { computed, onBeforeMount, onMounted, ref } from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    import axios from 'axios'
    import { Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, TimeScale, Title, Tooltip } from 'chart.js'
    import 'chartjs-adapter-date-fns'
    import DataTable from 'datatables.net-vue3'
    import DataTablesCore from 'datatables.net-bs5'
    import { MbscDatepicker, MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { Line } from 'vue-chartjs'

    ChartJS.register(Legend, LinearScale, LineElement, PointElement, TimeScale, Title, Tooltip)
    DataTable.use(DataTablesCore)

    const route = useRoute()
    const router = useRouter()
    const table = ref<typeof DataTable>(DataTable)

    const isLoading = ref(false)
    const chartDataIsLoading = ref(false)
    const dataTableIsLoading = ref(false)
    const sellersWithViolationsIsLoading = ref(false)
    const totalViolationsIsLoading = ref(false)
    const isSnackbarOpen = ref(false)
    const snackbarMessage = ref('')

    const amazonSellerId = ref('A2R2RITDJNW1Q6')
    const amazonSellerName = ref('Amazon.com')
    const chartDataDto = ref([])
    const productDto = ref({})
    const productIdParam = ref(route.query.productId)
    const selectedSellerId = ref('')
    const sellersWithViolationsDto = ref()
    const totalViolationsDto = ref({})

    const appliedStartDate = ref(new Date())
    const appliedEndDate = ref(new Date())
    const startDate = ref(new Date(route.query.startDate + ''))
    const endDate = ref(new Date(route.query.endDate + ''))
    appliedStartDate.value = new Date(startDate.value)
    appliedEndDate.value = new Date(endDate.value)

    onBeforeMount(async () => {
        if (route.query.productId == null || route.query.startDate == null || route.query.endDate == null) {
            router.push({ name: 'products-dashboard-products-view' })
        }
    })

    onMounted(async () => {
        try {
            productDto.value = (await axios.get('/api/product/dashboard/product/?productId=' + productIdParam.value)).data
            await loadData()
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onDateChanged = (() => {
        if (startDate.value >= endDate.value) {
            const newStartDate = new Date(formattedEndDate.value)
            newStartDate.setDate(newStartDate.getDate() - 1)
            startDate.value = new Date(newStartDate)
        }
    })

    const onApplyClicked = (async () => {
        appliedStartDate.value = new Date(startDate.value)
        appliedEndDate.value = new Date(endDate.value)
        await loadData()
    })

    const loadData = (async () => {
        chartDataIsLoading.value = true
        sellersWithViolationsIsLoading.value = true
        totalViolationsIsLoading.value = true

        try {
            sellersWithViolationsDto.value = (await axios.get('/api/product/dashboard/sellers-with-violations/?productId=' + productIdParam.value + '&startDate=' + formattedStartDate.value + '&endDate=' + formattedEndDate.value)).data
            sellersWithViolationsIsLoading.value = false
            if (selectedSellerId.value == '') {
                selectedSellerId.value = sellersWithViolationsDto.value[0].sellerId
            }
            reloadTable()
            await Promise.all([
                axios.get('/api/product/dashboard/seller-violations-chart-data/?productId=' + productIdParam.value + '&sellerId=' + selectedSellerId.value + '&startDate=' + formattedStartDate.value + '&endDate=' + formattedEndDate.value)
                    .then((chartData) => {
                        chartDataDto.value = chartData.data
                        chartDataIsLoading.value = false
                    }),
                axios.get('/api/product/dashboard/seller-total-violations/?productId=' + productIdParam.value + '&sellerId=' + selectedSellerId.value + '&startDate=' + formattedStartDate.value + '&endDate=' + formattedEndDate.value)
                    .then((totalViolations) => {
                        totalViolationsDto.value = totalViolations.data
                        totalViolationsIsLoading.value = false
                    })
            ])
        } catch {
            showSnackbar('An error occurred. Please contact support.')
            chartDataIsLoading.value = false
            sellersWithViolationsIsLoading.value = false
            totalViolationsIsLoading.value = false
        }
    })

    const onClearButtonClicked = (() => {
        router.push({ name: 'products-dashboard-products-view', query: { startDate: formatDate(appliedStartDate.value), endDate: formatDate(appliedEndDate.value) } })
    })

    const onProductTitleClicked = (() => {
        window.open(`https://www.amazon.com/dp/${productDto.value.asin}`, '_blank')
    })

    const onExportClicked = (async () => {
        const response = await axios({
            url: '/api/product/dashboard/seller-violation-history/export?productId=' + productIdParam.value + '&sellerId=' + selectedSellerId.value + '&startDate=' + formattedStartDate.value + '&endDate=' + formattedEndDate.value,
            method: 'GET',
            responseType: 'blob',
        })
        if (response && response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${selectedSellerId.value}_${formattedAppliedStartDate.value}_to_${formattedAppliedEndDate.value}.csv`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    })

    const formattedAppliedStartDate = computed(() => {
        return formatDate(appliedStartDate.value)
    })

    const formattedAppliedEndDate = computed(() => {
        return formatDate(appliedEndDate.value)
    })

    const formattedStartDate = computed(() => {
        return formatDate(startDate.value)
    })

    const formattedEndDate = computed(() => {
        return formatDate(endDate.value)
    })

    const formatDate = ((date: Date) => {
        let year = date.getFullYear()
        let month: any = date.getMonth() + 1
        let day: any = date.getDate()
        if (month < 10) month = '0' + month
        if (day < 10) day = '0' + day
        return `${month}/${day}/${year}`
    })

    const formatDateTime = ((utcDate: Date, timeZone?: string): string => {
        const actualUtcDate = new Date(utcDate + 'UTC')
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: timeZone ? timeZone : userTimeZone,
            timeZoneName: 'short'
        }

        const formatter = new Intl.DateTimeFormat('en-US', options)
        const formattedDate = formatter.format(actualUtcDate)

        return formattedDate
    })

    const maxStartDate = computed(() => {
        const maxStartDt = new Date()
        maxStartDt.setDate(maxEndDate.value.getDate() - 1)
        return maxStartDt
    })

    const maxEndDate = computed(() => {
        const today = new Date()
        return today
    })

    const formatCurrency = ((value: number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
    })

    const trimToLength = ((input: string, length: number): string => {
        if (input && input.length > length) {
            return input.substring(0, length) + '...'
        } else {
            return input
        }
    })

    const showSnackbar = ((msg: string) => {
        snackbarMessage.value = msg
        isSnackbarOpen.value = true
    })

    const chartData = computed(() => {
        let deduppedChartData = chartDataDto.value.filter((v, i, a) => a.findIndex(v2 => (JSON.stringify(v2) === JSON.stringify(v))) === i)

        return {
            type: 'line',
            datasets: [{
                data: chartDataDto.value.length > 0 ? deduppedChartData.map((item: any) => ({ x: item.x, y: item.y2 }))  : [],
                label: "MAP",
                borderColor: "#212121",
                backgroundColor: "#212121",
                pointRadius: 4,
                fill: false,
                lineTension: 0.4,
            }, {
                data: chartDataDto.value.length > 0 ? deduppedChartData.map((item: any) => ({ x: item.x, y: item.y })) : [],
                label: "Landed Price",
                borderColor: "#CDD5DD",
                backgroundColor: "#FF0030",
                pointRadius: 4,
                fill: false,
                lineTension: 0.4,
            }]
        }
    })

    const chartOptions = computed(() => {
        return {
            responsive: true,
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: chartTimeUnit.value
                    },
                    grid: {
                        display: false
                    }
                },
                y: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        precision: 2,
                        callback: function (value: any) {
                            return '$' + value.toFixed(2)
                        }
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    })

    const chartTimeUnit = computed(() => {
        let differenceInTime = appliedEndDate.value.getTime() - appliedStartDate.value.getTime()
        let differenceInDays = (Math.round(differenceInTime / (1000 * 3600 * 24)))
        let timeUnit = ''
        if (differenceInDays <= 14) {
            timeUnit = 'day'
        } else if (differenceInDays <= 90) {
            timeUnit = 'week'
        } else if (differenceInDays <= 366) {
            timeUnit = 'month'
        } else {
            timeUnit = 'year'
        }
        return timeUnit
    })

    const tableColumns = ref([
        {
            data: 'date',
            render: function (data: any) {
                return formatDateTime(new Date(data))
            }
        },
        {
            data: 'map',
            render: function (data: any) {
                return formatCurrency(data)
            }
        },
        {
            data: 'lowestPrice',
            render: function (data: any) {
                return formatCurrency(data)
            }
        },
        {
            data: 'corrected',
            render: function (data: any) {
                return data == true ? `<span style="padding-left: 25px;"><i class="far fa-check"></i></span>` : 
                `<span style="padding-left: 25px;"><i class="far fa-times opacity-50"></i></span>`
            }
        },
    ])

    const tableOptions = ref({
        responsive: true,
        dom: 'rtipl',
        processing: true,
        serverSide: true,
        ajax: {
            url: `/api/product/dashboard/seller-violation-history/page`,
            type: 'POST',
            contentType: 'application/json',
            data: (d: any) => getPostData(d),
            dataSrc: (json: any) => getDataSrc(json),
            error: () => () => showSnackbar('An error occurred when loading the table. Please contact support.'),
            columns: tableColumns.value,
            preDrawCallback: () => dataTableIsLoading.value = true,
            drawCallback: () => dataTableIsLoading.value = false
        },
        language: {
            lengthMenu: '_MENU_'
        },
        lengthMenu: [10, 50, 100, 1000],
        select: {
            style: 'multi',
            selector: 'td.select-checkbox'
        }
    })

    const getPostData = ((d: any) => {
        const pageNumber = d.start / d.length + 1
        const pageSize = d.length
        let startDate = new Date(appliedStartDate.value)
        let endDate = new Date(appliedEndDate.value)
        return JSON.stringify({ PageIndex: pageNumber, PageSize: pageSize, ProductId: productIdParam.value, SellerId: selectedSellerId.value, StartDate: startDate, EndDate: endDate })
    })

    const getDataSrc = ((response: any) => {
        return response.sellerViolationHistoryList
    })

    const reloadTable = (() => {
        table.value.dt.ajax.reload()
    })

</script>
