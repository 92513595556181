import axios from 'axios'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useCookie } from 'vue-cookie-next'

import LoginPage from '@/views/account/LoginPage.vue'
import ForgotPasswordPage from '@/views/account/ForgotPasswordPage.vue'
import SetPasswordPage from '@/views/account/SetPasswordPage.vue'
import AdminUsersPage from '@/views/admin/AdminUsersPage.vue'
import SupplierUsersPage from '@/views/admin/SupplierUsersPage.vue'
import SuppliersPage from '@/views/admin/SuppliersPage.vue'
import BrandsPage from '@/views/admin/BrandsPage.vue'
import RetailerLandingPage from '@/views/retailer/RetailerLandingPage.vue'
import ManageBrandsPage from '@/views/supplier/ManageBrandsPage.vue'
import ManageProductsPage from '@/views/supplier/ManageProductsPage.vue'
import ManageRetailersPage from '@/views/supplier/ManageRetailersPage.vue'
import ManageUsersPage from '@/views/supplier/ManageUsersPage.vue'
import ProductsDashboardProductsView from '@/views/supplier/ProductsDashboardProductsView.vue'
import ProductsDashboardSellersView from '@/views/supplier/ProductsDashboardSellersView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/NotFoundPage.vue')
    },
    {
        path: '/',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/account/forgot-password',
        name: 'forgot-password',
        component: ForgotPasswordPage
    },
    {
        path: '/account/set-password',
        name: 'set-password',
        component: SetPasswordPage
    },
    {
        path: '/admin/admin-users',
        name: 'admin-users',
        component: AdminUsersPage
    },
    {
        path: '/admin/admin-supplier-users',
        name: 'admin-supplier-users',
        component: SupplierUsersPage
    },
    {
        path: '/admin/admin-suppliers',
        name: 'admin-suppliers',
        component: SuppliersPage
    },
    {
        path: '/admin/admin-brands',
        name: 'admin-brands',
        component: BrandsPage
    },
    {
        path: '/retailer/retailer-landing',
        name: 'retailer-landing',
        component: RetailerLandingPage
    },
    {
        path: '/supplier/manage-brands',
        name: 'manage-brands',
        component: ManageBrandsPage
    },
    {
        path: '/supplier/manage-products',
        name: 'manage-products',
        component: ManageProductsPage
    },
    {
        path: '/supplier/manage-retailers',
        name: 'manage-retailers',
        component: ManageRetailersPage
    },
    {
        path: '/supplier/manage-users',
        name: 'manage-users',
        component: ManageUsersPage
    },
    {
        path: '/supplier/products-dashboard-products-view',
        name: 'products-dashboard-products-view',
        component: ProductsDashboardProductsView
    },
    {
        path: '/supplier/products-dashboard-sellers-view',
        name: 'products-dashboard-sellers-view',
        component: ProductsDashboardSellersView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active'
})

router.beforeEach(async (to, from, next) => {
    const cookies = useCookie()

    const superAdminHome = { name: 'admin-users' }
    const nonSuperAdminHome = { name: 'products-dashboard-products-view' }
    const loginPage = { name: 'login' }

    const isAuthenticated = (await axios.get('/api/user/is-authenticated')).data.isAuthenticated
    const isSuperAdmin = cookies.getCookie('role:SuperAdmin') === 'SuperAdmin'

    const isNotAuthenticatedAndIsRetailerLandingRoute = to.path.includes('/retailer/retailer-landing') && !isAuthenticated
    const isLoginRouteAndUserIsAuthenticatedSuperAdmin = to.path == '/' && isAuthenticated && isSuperAdmin
    const isLoginRouteAndUserIsAuthenticatedUser = to.path == '/' && isAuthenticated && !isSuperAdmin
    const isNotLoginRouteAndUserIsNotAuthenticated = !(to.path == '/') && !(to.path.includes('/account')) && !(to.path.includes('/retailer/retailer-landing')) && !isAuthenticated
    const isAdminRouteAndUserIsNotSuperAdmin = to.path.includes('/admin/') && !isSuperAdmin
    const isNonAdminRouteAndUserIsSuperAdmin = to.path.includes('/supplier/') && isSuperAdmin

    if (isAuthenticated) await axios.put('/api/user/last-active')

    if (isNotAuthenticatedAndIsRetailerLandingRoute) {
        next()
    } else if (isLoginRouteAndUserIsAuthenticatedSuperAdmin) {
        next(superAdminHome)
    } else if (isLoginRouteAndUserIsAuthenticatedUser) {
        next(nonSuperAdminHome)
    } else if (isNotLoginRouteAndUserIsNotAuthenticated) {
        cookies.removeCookie('role:SuperAdmin')
        next(loginPage)
    } else if (isAdminRouteAndUserIsNotSuperAdmin) {
        next(nonSuperAdminHome)
    } else if (isNonAdminRouteAndUserIsSuperAdmin) {
        next(superAdminHome)
    } else {
        next()
    }
})

export default router
