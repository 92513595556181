<template>
    <nav id="admin-menu" :class="{'show': showMobileFriendlyMenu}" @click="showMobileFriendlyMenu = !showMobileFriendlyMenu">
        <a role="button" class="mobile-menu-btn"><i class="fal fa-bars fa-lg"></i></a>
        <div class="logo">
            <img src="@/assets/images/logo-white.svg" alt="MapAuthority logo" />
        </div>
        <ul class="admin-nav">
            <li>
                <a href="#userMenu"
                   :class="{ active: $route.path.includes('/admin-supplier-users') ||  $route.path.includes('/admin-users') }"
                   @click.prevent="showUserMenu = !showUserMenu">
                    <span>
                        <span class="icon"><i class="fal fa-user"></i></span>
                        <span class="menu-text"> Users</span>
                    </span>
                    <i class="fal fa-chevron-down"></i>
                </a>
                <ul class="sub-menu"
                    :class="{'show': showUserMenu, 'collapse': !showUserMenu }"
                    id="userMenu">
                    <li><router-link :to="{ name: 'admin-supplier-users' }">Supplier Users</router-link></li>
                    <li><router-link :to="{ name: 'admin-users' }">Admin Users</router-link></li>
                </ul>
            </li>
            <li>
                <router-link :to="{ name: 'admin-suppliers' }">
                    <span>
                        <span class="icon"><i class="fal fa-shop"></i></span>
                        <span class="menu-text"> Suppliers</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'admin-brands' }">
                    <span>
                        <span class="icon"><i class="fal fa-tag"></i></span>
                        <span class="menu-text"> Brands<span v-if="filteredPendingBrands && filteredPendingBrands.length > 0" class="badge text-bg-primary text-white text-600 rounded-pill ms-2">{{ filteredPendingBrands.length }}</span></span>
                    </span>
                </router-link>
            </li>
            <!--<li>
                <a href="#">
                    <span>
                        <span class="icon"><i class="fal fa-cog"></i></span>
                        <span class="menu-text"> Settings</span>
                    </span>
                </a>
            </li>-->
        </ul>
    </nav>
</template>


<script lang="ts" setup>

    import axios from 'axios'
    import { computed, onMounted, ref } from 'vue'

    const brandDataDto = ref([])
    const showMobileFriendlyMenu = ref(false)
    const showUserMenu = ref(true)

    onMounted(async () => {
        let result = await axios.get('/api/brand')
        brandDataDto.value = result ? result.data : []
    })

    const filteredPendingBrands = computed(() => {
        let result = brandDataDto.value

        if (result && result.length > 0) {
            result = result.filter((brand) => brand.approval === 'Pending' && brand.isActive == true)
        }

        return result
    })
</script>