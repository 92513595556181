<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />
    <div class="pre-loader" v-if="isLoading"></div>

    <div class="site-container">
        <AdminMenu />
        <div class="admin-page-content">
            <div class="container">
                <UserProfileDropdown />
                <div class="row align-items-center mb-5">
                    <div class="col-md-auto mb-3 mb-md-0">
                        <h2 class="m-0 text-strong"><i class="fal fa-user"></i> Supplier Users</h2>
                    </div>
                </div>
                <div class="input-group input-group-lg mb-3">
                    <input type="text"
                           class="form-control"
                           id="input-search"
                           placeholder="Search supplier users..."
                           aria-label="Search supplier users..."
                           v-model="searchValue"
                           v-on:keyup.enter="onSearchClicked()">
                    <button class="btn btn-white"
                            id="btn-search"
                            type="button"
                            @click="onSearchClicked()">
                        <i class="fal fa-search"></i>
                    </button>
                </div>
                <div class="row mb-4">
                    <div class="col-xxl-2 col-xl-5">
                        <label class="form-label small">Status</label>
                        <div class="dropdown w-100">
                            <button class="btn btn-white btn-sm dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{ selectedStatus }}</span>
                            </button>
                            <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                <li v-for="option in statusOptions" :key="option">
                                    <a class="dropdown-item"
                                       :class="{'active': selectedStatus == option }"
                                       href="#"
                                       @click.prevent="selectedStatus = option">{{ option }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label small">Supplier</label>
                        <input type="text"
                               class="form-control form-control-sm"
                               id="input-supplier-search"
                               placeholder="Supplier"
                               v-model="searchSupplierValue" />
                    </div>
                </div>
                <div class="card" v-if="showAllClicked">
                    <table class="table table-hover align-middle m-0">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Phone</th>
                                <th>Supplier</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in filteredSupplierUsers"
                                :key="user.userId"
                                :class="{'deactivated' : !user.isActive}">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <div class="avatar avatar-sm table-avatar">{{ user.firstName.charAt(0) + user.lastName.charAt(0) }}</div>
                                        </div>
                                        <div>
                                            <p class="m-0 table-name">{{ user.firstName + ' ' + user.lastName }}</p>
                                            <small class="text-muted table-email">{{ user.email }}</small>
                                        </div>
                                    </div>
                                </td>
                                <td class="table-phone">{{ formatPhone(user.phone) }}</td>
                                <td class="table-supplier-name"
                                    v-if="user.companyIds.length > 1">
                                    <a href="#"
                                       class="supplier-popover"
                                       role="button"
                                       data-bs-toggle="popover"
                                       data-bs-html="true"
                                       data-bs-trigger="hover"
                                       :data-bs-content="getCompanyList(user.companyIds)"
                                       v-popover>{{ user.companyIds.length }} Suppliers</a>
                                </td>
                                <td class="table-supplier-name"
                                    v-if="user.companyIds.length == 1">
                                    {{ getCompanyNames(user.companyIds) }}
                                </td>
                                <td class="table-status">{{ user.isActive ? 'Active' : 'Deactivated' }}</td>
                                <td class="text-right">
                                    <div class="dropdown">
                                        <button class="btn btn-white border-0 action-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="far fa-ellipsis-v fa-lg"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a class="dropdown-item"
                                                   id="dropdown-item-edit"
                                                   data-bs-toggle="modal"
                                                   data-bs-target="#edit-supplier-user"
                                                   href="#"
                                                   @click.prevent="onEditUserClicked(user)"><i class="fa-light fa-pencil fa-fw"></i> Edit</a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item"
                                                   id="dropdown-item-impersonate"
                                                   v-if="user.isActive"
                                                   href="#"
                                                   @click.prevent="onImpersonateClicked(user.userId)"><i class="fa-light fa-theater-masks fa-fw"></i> Impersonate</a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item"
                                                   id="dropdown-item-send-password-reset"
                                                   v-if="user.isActive"
                                                   href="#"
                                                   @click.prevent="onSendPasswordResetClicked(user.email)"><i class="fa-light fa-lock fa-fw"></i> Send Password Reset</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="card" v-if="!showAllClicked">
                    <div class="my-4 text-center">
                        <h3 class="mb-3"><i class="fa-regular fa-search"></i></h3>
                        <p>Enter search parameters above to refine results.</p>
                        <button type="button"
                                id="btn-show-all"
                                class="btn btn-sm btn-outline-secondary text-600"
                                @click="onShowAllClicked()">
                            Show All
                        </button>
                    </div>
                </div>
                <div class="card" v-if="showAllClicked && filteredSupplierUsers && filteredSupplierUsers.length === 0">
                    <div class="my-4 text-center">
                        <h3 class="mb-3"><i class="fa-regular fa-search"></i></h3>
                        <p>No users found.</p>
                    </div>
                </div>
            </div>
        </div>
        <UserFooter />
    </div>

    <!-- Edit Supplier User Modal -->
    <div class="modal fade"
         id="edit-supplier-user"
         tabindex="-1"
         data-bs-backdrop="static"
         aria-labelledby="edit-supplier-user"
         aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">Edit Supplier User</h1>
                    <button type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref="closeModalButton"></button>
                </div>
                <div class="modal-body" id="edit-supplier-user-modal-body">
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="userSupplierCompanies" class="form-label">Supplier<sup class="text-danger">*</sup></label>
                            <div id="userSupplierCompanies"
                                 :class="{'is-invalid' : !validSelectedCompanies()}">
                                <MbscSelect theme="ios"
                                            themeVariant="light"
                                            :data="filteredSupplierCompanies"
                                            v-model="editSupplierUser.companyIds"
                                            :selectMultiple="true"
                                            :filter="true"
                                            :context="dropdownContext"></MbscSelect>
                            </div>

                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> At least one supplier must be selected.
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="userFirstName" class="form-label">First Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="userFirstName"
                                   placeholder="First Name"
                                   v-model="editSupplierUser.firstName"
                                   v-bind:class="{'is-invalid' : !validFirstName() && firstNameBlured}" v-on:blur="firstNameBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="userLastName" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="userLastName"
                                   placeholder="Last Name"
                                   v-model="editSupplierUser.lastName"
                                   v-bind:class="{'is-invalid' : !validLastName() && lastNameBlured}" v-on:blur="lastNameBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="userEmail" class="form-label">Email Address<sup class="text-danger">*</sup></label>
                            <input type="email"
                                   class="form-control"
                                   id="userEmail"
                                   placeholder="Email Address"
                                   v-model="editSupplierUser.email"
                                   v-bind:class="{'is-invalid' : !validEmail() && emailBlured}" v-on:blur="emailBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must contain a valid email.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="userPhone" class="form-label">Phone<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="userPhone"
                                   placeholder="Phone"
                                   v-model="editSupplierUser.phone"
                                   v-bind:class="{'is-invalid' : !validPhone() && phoneBlured}" v-on:blur="phoneBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must contain a 10 digit phone number.
                            </div>
                        </div>
                    </div>
                    <div class="col-md mb-3">
                        <label for="userStatus" class="form-label d-block">Status</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input"
                                   type="checkbox"
                                   role="switch"
                                   id="flexSwitchCheckChecked"
                                   v-model="editSupplierUser.isActive"
                                   checked>
                            <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary btn-round me-2" data-bs-dismiss="modal">Cancel</button>
                    <button type="button"
                            class="btn btn-secondary btn-round"
                            id="btn-submit"
                            @click="onSubmitClicked()">
                        Save User
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import axios from 'axios'
    import { CustomRegex } from '@/assets/CustomRegex'
    import { MbscSelect, MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { CompanyDto, SupplierUserDto } from '@/assets/types'
    import AdminMenu from '@/components/AdminMenu.vue'
    import UserFooter from '@/components/UserFooter.vue'
    import UserProfileDropdown from '@/components/UserProfileDropdown.vue'
    import { computed, ref, onMounted } from 'vue'
    import { useCookie } from 'vue-cookie-next'
    import { useRouter } from 'vue-router'

    const emptySupplierUser: Readonly<SupplierUserDto> = {
        userId: null,
        companyIds: [],
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        isActive: true,
        isSuperAdmin: true
    }

    const cookies = useCookie()
    const router = useRouter()

    const closeModalButton = ref<HTMLButtonElement>()
    const dropdownContext = ref('#edit-supplier-user-modal-body')
    const editSupplierUser = ref<SupplierUserDto>(JSON.parse(JSON.stringify(emptySupplierUser)))
    const isLoading = ref(false)
    const isSnackbarOpen = ref(false)
    const searchValue = ref('')
    const searchSupplierValue = ref('')
    const selectedStatus = ref('Active')
    const showAllClicked = ref(false)
    const snackbarMessage = ref('')
    const supplierCompanies = ref(Array<CompanyDto>())
    const supplierUsers = ref(Array<SupplierUserDto>())
    const statusOptions = ref(['All', 'Active', 'Deactivated'])

    const emailBlured = ref(false)
    const firstNameBlured = ref(false)
    const lastNameBlured = ref(false)
    const phoneBlured = ref(false)

    onMounted(async () => {
        try {
            supplierCompanies.value = (await axios.get('/api/admin/supplier-companies')).data
        } catch (error) {
            showSnackbar('Failed to retrieve supplier companies. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const onSearchClicked = (async () => {
        await loadSupplierUsers()
    })

    const loadSupplierUsers = (async () => {
        try {
            let endpoint: string = searchValue.value ? `/api/admin/supplier-users/${searchValue.value}` : '/api/admin/supplier-users'
            supplierUsers.value = (await axios.get(endpoint)).data
            showAllClicked.value = true
        } catch (error) {
            showSnackbar('Failed to retrieve supplier users. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const onShowAllClicked = (async () => {
        await loadSupplierUsers()
    })

    const onSendPasswordResetClicked = (async (email: string) => {
        try {
            isLoading.value = true
            await axios.post('/api/user/password-reset-request', {
                email: email
            })
            showSnackbar('Password reset email has been sent.')
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const onEditUserClicked = ((supplier: SupplierUserDto) => {
        editSupplierUser.value = JSON.parse(JSON.stringify(supplier))
    })

    const onImpersonateClicked = (async (userId: number) => {
        await axios.post('/api/user/start-impersonation', { userId: userId })
        cookies.removeCookie('role:SuperAdmin')
        router.push({ name: 'login' })
    })

    const onSubmitClicked = (async () => {
        try {
            if (!validate()) {
                return
            }
            editSupplierUser.value.phone = editSupplierUser.value.phone.replace(/\D/g, '')
            isLoading.value = true
            await axios.put('/api/admin/supplier-user', editSupplierUser.value)
            showSnackbar('Supplier user was updated.')
            closeModalButton.value?.click()
            await loadSupplierUsers()
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const validate = (() => {
        firstNameBlured.value = true
        lastNameBlured.value = true
        emailBlured.value = true
        phoneBlured.value = true
        return validSelectedCompanies() && validFirstName() && validLastName() && validEmail() && validPhone()
    })

    const validSelectedCompanies = (() => {
        if (editSupplierUser.value.companyIds.length > 0) {
            return true
        }
    })

    const validFirstName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(editSupplierUser.value.firstName.toLowerCase())) {
            return true
        }
    })

    const validLastName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(editSupplierUser.value.lastName.toLowerCase())) {
            return true
        }
    })

    const validEmail = (() => {
        let regex = CustomRegex.isEmailAddress
        if (regex.test(editSupplierUser.value.email.toLowerCase())) {
            return true
        }
    })

    const validPhone = (() => {
        let regex = CustomRegex.isPhoneNumber
        if (regex.test(editSupplierUser.value.phone.toLowerCase())) {
            return true
        }
    })

    const filteredSupplierUsers = computed(() => {
        let result: Array<SupplierUserDto> = supplierUsers.value

        if (result && selectedStatus.value === 'Active') {
            result = result.filter((user) => user.isActive === true)
        } else if (result && selectedStatus.value === 'Deactivated') {
            result = result.filter((user) => user.isActive === false)
        }

        if (result && searchSupplierValue.value) {
            result = result.filter((user) =>
                getCompanyNames(user.companyIds)?.toLowerCase().includes(searchSupplierValue.value.toLowerCase()))
        }

        return result
    })

    const filteredSupplierCompanies = computed(() => {
        return supplierCompanies.value ? supplierCompanies.value.filter(company => company.isActive)
            .map(company => ({ text: company.name, value: company.id })) : []
    })

    const getCompanyNames = ((ids: Array<number>) => {
        if (supplierCompanies.value) {
            let filteredCompanies = supplierCompanies.value.filter((company: CompanyDto) => ids.includes(company.id))
            let names: Array<string> = filteredCompanies.map((company) => company.name)
            return names.join(', ')
        }
    })

    const getCompanyList = ((ids: Array<number>) => {
        if (supplierCompanies.value) {
            let filteredCompanies = supplierCompanies.value.filter((company: CompanyDto) => ids.includes(company.id))
            let names: Array<string> = filteredCompanies.map((company) => company.name)
            return `<ul class='mb-0 ps-3'><li>${names.join('</li><li>')}</li></ul>`
        }
    })

    const formatPhone = ((phone: string) => {
        return phone.replace(CustomRegex.matchPhoneNumber, '$1-$2-$3')
    })

    const showSnackbar = ((message: string) => {
        snackbarMessage.value = message
        isSnackbarOpen.value = true
    })
</script>