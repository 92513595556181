export abstract class CustomRegex {
    public static isAlphaNumericAndTenCharacters: RegExp = /^[a-zA-Z0-9]{10}$/
    public static isBetweenOneAndFiftyChars: RegExp = /^.{1,50}$/
    public static isBetweenOneAndOneHundredChars: RegExp = /^.{1,100}$/
    public static isBetweenOneAndTwoHundredFiftyFourChars: RegExp = /^.{1,254}$/
    public static isBetweenOneAndOneThousandChars: RegExp = /^.{1,1000}$/
    public static isEmailAddress: RegExp = /(.+)@(.+){2,}\.(.+){2,}/
    public static isNumberWithTwoDecimalPlaces: RegExp = /^\d*\.\d{2}$/
    public static isPhoneNumber: RegExp = /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    public static matchNonDigit: RegExp = /\D/g
    public static matchPhoneNumber: RegExp = /^(\d{3})(\d{3})(\d{4}).*/
}

