<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />

    <div class="pre-loader" v-if="isLoading"></div>

    <div class="site-container">
        <UserMenu />
        <div class="admin-page-content">
            <ImpersonateBanner />
            <div class="container">
                <UserProfileDropdown />
                <div class="row align-items-center mb-5">
                    <div class="col-md-auto mb-3 mb-md-0">
                        <h2 class="m-0 text-strong"><svg class="svg-inline--fa fa-user" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480H416c-1.2-79.7-66.2-144-146.3-144H178.3c-80 0-145 64.3-146.3 144zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"></path></svg><!-- <i class="fal fa-user"></i> --> Users</h2>
                    </div>
                    <div class="col-md-auto">
                        <button type="button" 
                                id="btn-add"
                                data-bs-toggle="modal" 
                                data-bs-target="#user-modal" 
                                class="btn btn-sm px-3 btn-primary btn-round hvr-grow mobile-100"
                                @click="onNewUserClicked()">
                        <svg class="svg-inline--fa fa-plus" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M240 64c0-8.8-7.2-16-16-16s-16 7.2-16 16V240H32c-8.8 0-16 7.2-16 16s7.2 16 16 16H208V448c0 8.8 7.2 16 16 16s16-7.2 16-16V272H416c8.8 0 16-7.2 16-16s-7.2-16-16-16H240V64z"></path></svg><!-- <i class="fal fa-plus"></i> --> 
                        New</button>
                    </div>
                </div>
                <div class="input-group input-group-lg mb-3">
                    <input type="text"
                           class="form-control"
                           id="input-search"
                           placeholder="Search users..."
                           aria-label="Search users..."
                           v-model="searchValue"
                           v-on:keyup.enter="onSearchClicked()">
                    <button class="btn btn-white"
                            id="btn-search"
                            type="button"
                            @click="onSearchClicked()">
                        <i class="fal fa-search"></i>
                    </button>
                </div>
                <div class="row mb-4">
                    <div class="col-xxl-2 col-xl-5">
                        <label class="form-label small">Status</label>
                        <div class="dropdown w-100">
                            <button class="btn btn-white btn-sm dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{ selectedStatus }}</span>
                            </button>
                            <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                <li v-for="option in statusOptions" :key="option">
                                    <a class="dropdown-item"
                                       :class="{'active': selectedStatus == option }"
                                       href="#"
                                       @click.prevent="selectedStatus = option">{{ option }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="showAllClicked">
                    <table class="table table-hover align-middle m-0">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Phone</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in filteredSupplierUsers"
                                :key="user.userId"
                                :class="{'deactivated' : !user.isActive}">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <div class="avatar avatar-sm table-avatar">{{ user.firstName.charAt(0) + user.lastName.charAt(0) }}</div>
                                        </div>
                                        <div>
                                            <p class="m-0 table-name">{{ user.firstName + ' ' + user.lastName }}</p>
                                            <small class="text-muted table-email">{{ user.email }}</small>
                                        </div>
                                    </div>
                                </td>
                                <td class="table-phone">{{ formatPhone(user.phone) }}</td>
                                <td class="table-status">{{ user.isActive ? 'Active' : 'Deactivated' }}</td>
                                <td class="text-right">
                                    <div class="dropdown">
                                        <button class="btn btn-white border-0 action-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="far fa-ellipsis-v fa-lg"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a class="dropdown-item"
                                                   id="dropdown-item-edit"
                                                   data-bs-toggle="modal"
                                                   data-bs-target="#user-modal"
                                                   href="#"
                                                   @click.prevent="onEditUserClicked(user)"><i class="fa-light fa-pencil fa-fw"></i> Edit</a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item"
                                                   id="dropdown-item-send-password-reset"
                                                   v-if="user.isActive"
                                                   href="#"
                                                   @click.prevent="onSendPasswordResetClicked(user.email)"><i class="fa-light fa-lock fa-fw"></i> Send Password Reset</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="card" v-if="!showAllClicked">
                    <div class="my-4 text-center">
                        <h3 class="mb-3"><i class="fa-regular fa-search"></i></h3>
                        <p>Enter search parameters above to refine results.</p>
                        <button type="button"
                                id="btn-show-all"
                                class="btn btn-sm btn-outline-secondary text-600"
                                @click="onShowAllClicked()">
                            Show All
                        </button>
                    </div>
                </div>
                <div class="card" v-if="showAllClicked && filteredSupplierUsers && filteredSupplierUsers.length === 0">
                    <div class="my-4 text-center">
                        <h3 class="mb-3"><i class="fa-regular fa-search"></i></h3>
                        <p>No users found.</p>
                    </div>
                </div>
            </div>
        </div>
        <UserFooter />
    </div>

    <!-- New/Edit User Modal -->
    <div class="modal fade" id="user-modal" tabindex="-1" aria-labelledby="user-modal" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">{{ isEditing ? 'Edit' : 'New'}} User</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="userFirstName" class="form-label">First Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="userFirstName"
                                   placeholder="First Name"
                                   v-model="addOrEditSupplierUser.firstName"
                                   v-bind:class="{'is-invalid' : !validFirstName() && firstNameBlured}" v-on:blur="firstNameBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="userLastName" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="userLastName"
                                   placeholder="Last Name"
                                   v-model="addOrEditSupplierUser.lastName"
                                   v-bind:class="{'is-invalid' : !validLastName() && lastNameBlured}" v-on:blur="lastNameBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 50 characters or less.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="userEmail" class="form-label">Email Address<sup class="text-danger">*</sup></label>
                            <input type="email"
                                   class="form-control"
                                   id="userEmail"
                                   placeholder="Email Address"
                                   v-model="addOrEditSupplierUser.email"
                                   v-bind:class="{'is-invalid' : !validEmail() && emailBlured}" v-on:blur="emailBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must contain a valid email.
                            </div>
                        </div>
                        <div class="col-md mb-3">
                            <label for="userPhone" class="form-label">Phone<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="userPhone"
                                   placeholder="Phone"
                                   v-model="addOrEditSupplierUser.phone"
                                   v-bind:class="{'is-invalid' : !validPhone() && phoneBlured}" v-on:blur="phoneBlured = true">
                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must contain a 10 digit phone number.
                            </div>
                        </div>
                    </div>
                    <div class="col-md mb-3">
                        <label for="userStatus" class="form-label d-block">Status</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input"
                                   type="checkbox"
                                   role="switch"
                                   id="flexSwitchCheckChecked"
                                   v-model="addOrEditSupplierUser.isActive"
                                   checked>
                            <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" 
                            ref="closeModalButton"
                            class="btn btn-outline-secondary btn-round me-2" 
                            data-bs-dismiss="modal"
                            @click="isEditing = false"
                            >Cancel</button>
                    <button type="button"
                            class="btn btn-secondary btn-round"
                            id="btn-submit"
                            @click="onSubmitClicked()">
                        Save User
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import ImpersonateBanner from '@/components/ImpersonateBanner.vue'
    import UserMenu from '@/components/UserMenu.vue'
    import UserFooter from '@/components/UserFooter.vue'
    import UserProfileDropdown from '@/components/UserProfileDropdown.vue'

    import axios, { AxiosError } from 'axios'
    import { CustomRegex } from '@/assets/CustomRegex'
    import { MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { SupplierUserDto } from '@/assets/types'
    import { computed, ref } from 'vue'

    const emptySupplierUser: Readonly<SupplierUserDto> = {
        userId: null,
        companyIds: [],
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        isActive: true,
        isSuperAdmin: true
    }

    const closeModalButton = ref<HTMLButtonElement>()
    const addOrEditSupplierUser = ref<SupplierUserDto>(JSON.parse(JSON.stringify(emptySupplierUser)))
    const isEditing = ref(false)
    const isLoading = ref(false)
    const isSnackbarOpen = ref(false)
    const searchValue = ref('')
    const selectedStatus = ref('Active')
    const showAllClicked = ref(false)
    const snackbarMessage = ref('')
    const supplierUsers = ref(Array<SupplierUserDto>())
    const statusOptions = ref(['All', 'Active', 'Deactivated'])

    const emailBlured = ref(false)
    const firstNameBlured = ref(false)
    const lastNameBlured = ref(false)
    const phoneBlured = ref(false)

    const onNewUserClicked = (() => {
        resetValidation()
        isEditing.value = false
    })

    const resetValidation = (() => {
        firstNameBlured.value = false
        lastNameBlured.value = false
        emailBlured.value = false
        phoneBlured.value = false
        addOrEditSupplierUser.value = JSON.parse(JSON.stringify(emptySupplierUser))
    })

    const onSearchClicked = (async () => {
        await loadSupplierUsers()
    })

    const loadSupplierUsers = (async () => {
        try {
            let endpoint: string = searchValue.value ? `/api/user/supplier-users/${searchValue.value}` : '/api/user/supplier-users'
            supplierUsers.value = (await axios.get(endpoint)).data
            showAllClicked.value = true
        } catch (error) {
            showSnackbar('Failed to retrieve supplier users. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const onShowAllClicked = (async () => {
        await loadSupplierUsers()
    })

    const onSendPasswordResetClicked = (async (email: string) => {
        try {
            isLoading.value = true
            await axios.post('/api/user/password-reset-request', {
                email: email
            })
            showSnackbar('Password reset email has been sent.')
        } catch (error) {
            showSnackbar('An error occurred. Please contact support.')
        } finally {
            isLoading.value = false
        }
    })

    const onEditUserClicked = ((supplier: SupplierUserDto) => {
        addOrEditSupplierUser.value = JSON.parse(JSON.stringify(supplier))
        isEditing.value = true
    })

    const onSubmitClicked = (async () => {
        try {
            if (!validate()) {
                return
            }
            addOrEditSupplierUser.value.phone = addOrEditSupplierUser.value.phone.replace(/\D/g, '')
            isLoading.value = true

            if (isEditing.value) {
                await axios.put('/api/user/supplier-user', addOrEditSupplierUser.value)
                showSnackbar('Supplier user was updated.')
            } else {
                await axios.post('/api/user/supplier-user', addOrEditSupplierUser.value)
                showSnackbar('User was added and welcome email was sent.')
            }
            
            closeModalButton.value?.click()
            await loadSupplierUsers()
        } catch (error) {
            const axiosError = error as AxiosError
            if (axiosError.response?.status == 400) {
                showSnackbar('' + axiosError.response?.data)
            } else {
                showSnackbar('An error occurred. Please contact support.')
            }
        } finally {
            isLoading.value = false
        }
    })

    const validate = (() => {
        firstNameBlured.value = true
        lastNameBlured.value = true
        emailBlured.value = true
        phoneBlured.value = true
        return validFirstName() && validLastName() && validEmail() && validPhone()
    })

    const validFirstName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(addOrEditSupplierUser.value.firstName.toLowerCase())) {
            return true
        }
    })

    const validLastName = (() => {
        let regex = CustomRegex.isBetweenOneAndFiftyChars
        if (regex.test(addOrEditSupplierUser.value.lastName.toLowerCase())) {
            return true
        }
    })

    const validEmail = (() => {
        let regex = CustomRegex.isEmailAddress
        if (regex.test(addOrEditSupplierUser.value.email.toLowerCase())) {
            return true
        }
    })

    const validPhone = (() => {
        let regex = CustomRegex.isPhoneNumber
        if (regex.test(addOrEditSupplierUser.value.phone.toLowerCase())) {
            return true
        }
    })

    const filteredSupplierUsers = computed(() => {
        let result: Array<SupplierUserDto> = supplierUsers.value

        if (result && selectedStatus.value === 'Active') {
            result = result.filter((user) => user.isActive === true)
        } else if (result && selectedStatus.value === 'Deactivated') {
            result = result.filter((user) => user.isActive === false)
        }

        return result
    })

    const formatPhone = ((phone: string) => {
        return phone.replace(CustomRegex.matchPhoneNumber, '$1-$2-$3')
    })

    const showSnackbar = ((message: string) => {
        snackbarMessage.value = message
        isSnackbarOpen.value = true
    })

</script>