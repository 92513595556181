<template>
    <MbscSnackbar display="top"
                  cssClass="mobiscroll-snackbar-dark"
                  :message="snackbarMessage"
                  :isOpen="isSnackbarOpen"
                  @close="isSnackbarOpen = false" />

    <div class="pre-loader" v-if="isLoading"></div>

    <div class="site-container">
        <UserMenu />
        <div class="admin-page-content">
            <ImpersonateBanner />
            <div class="container">
                <UserProfileDropdown />
                <div class="row align-items-center mb-5">
                    <div class="col-md-auto mb-3 mb-md-0">
                        <h2 class="m-0 text-strong"><i class="fal fa-tag"></i> Brands</h2>
                    </div>
                    <div class="col-md-auto">
                        <button type="button" 
                                id="btn-add-new-brand"
                                data-bs-toggle="modal" 
                                data-bs-target="#new-brand" 
                                class="btn btn-sm px-3 btn-primary btn-round hvr-grow mobile-100"><i class="fal fa-plus"></i> New</button>
                    </div>
                </div>
                <div class="input-group input-group-lg mb-3">
                    <input type="text" 
                           class="form-control" 
                           placeholder="Search brands..." 
                           aria-label="Search brands..."
                           v-model="searchValue"
                           v-on:keyup.enter="onSearchClicked()">
                    <button id="btn-search"
                            class="btn btn-white" 
                            type="button"
                            @click="onSearchClicked()"
                            ><i class="fal fa-search"></i></button>
                </div>
                <div class="row mb-4">
                    <div class="col-xxl-2 col-xl-5">
                        <label class="form-label small">Approval</label>
                        <div class="dropdown w-100">
                            <button class="btn btn-white btn-sm dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{ selectedApproval }}</span>
                            </button>
                            <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                <li v-for="option in approvalOptions" :key="option">
                                    <a class="dropdown-item"
                                       :class="{'active': selectedApproval == option }"
                                       href="#"
                                       @click.prevent="selectedApproval = option">{{ option }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-5">
                        <label class="form-label small">Status</label>
                        <div class="dropdown w-100">
                            <button class="btn btn-white btn-sm dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{ selectedStatus }}</span>
                            </button>
                            <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                <li v-for="option in statusOptions" :key="option">
                                    <a class="dropdown-item"
                                       :class="{'active': selectedStatus == option }"
                                       href="#"
                                       @click.prevent="selectedStatus = option">{{ option }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <table class="table table-hover align-middle m-0">
                        <thead>
                            <tr>
                                <th>Brand</th>
                                <th>Approval</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="brand in filteredBrands" 
                                :key="brand.id"
                                :class="{'deactivated': brand.isActive == false}">
                                <td class="table-brand">
                                    {{ brand.brandName }}
                                </td>
                                <td class="table-approval">
                                    <h5 class="m-0">
                                        <span class="text-500 badge"
                                              :class="{'bg-success': brand.approval == 'Approved',
                                                       'bg-gray': brand.approval == 'Pending',
                                                       'bg-danger': brand.approval == 'Denied',
                                                       'text-white': brand.approval == 'Approved',
                                                       'text-body': brand.approval == 'Pending',
                                                       'text-white': brand.approval == 'Denied'}">{{ brand.approval }}</span>
                                    </h5>
                                </td>
                                <td class="table-status">{{ brand.isActive ? 'Active' : 'Deactivated' }}</td>
                                <td class="text-right">
                                    <div class="dropdown">
                                        <button class="btn btn-white border-0 action-btn"
                                                type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="far fa-ellipsis-v fa-lg"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a class="dropdown-item"
                                                   id="#dpd-change-status"
                                                   href="#"
                                                   @click.prevent="onChangeStatusClicked(brand.id, brand.isActive)">
                                                    <i class="fa-light fa-fw"
                                                       :class="{ 'fa-times': brand.isActive,
                                                                 'fa-check': !brand.isActive}"></i> {{ brand.isActive ? 'Deactivate' : 'Activate'}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <UserFooter />
    </div>

    <!-- New Brand Modal -->
    <div class="modal fade" 
         id="new-brand" 
         tabindex="-1" aria-labelledby="new-brand" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-3">New Brand</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="brand-name" class="form-label">Brand Name<sup class="text-danger">*</sup></label>
                            <input type="text"
                                   class="form-control"
                                   id="brand-name"
                                   placeholder="Brand Name"
                                   v-model="addBrand.brandName"
                                   v-bind:class="{'is-invalid' : !validBrandName() && brandNameBlured}" v-on:blur="brandNameBlured = true">

                            <div class="invalid-feedback">
                                <i class="fas fa-exclamation-circle"></i> This field is required and must be 100 characters or less.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md mb-3">
                            <label for="userStatus" class="form-label d-block">Status</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input"
                                       type="checkbox"
                                       role="switch"
                                       id="flexSwitchCheckChecked"
                                       v-model="addBrand.isActive"
                                       checked />
                                <label class="form-check-label" for="flexSwitchCheckChecked">Active</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" 
                            class="btn btn-outline-secondary btn-round me-2" 
                            data-bs-dismiss="modal"
                            ref="closeModalButton">Cancel</button>
                    <button type="button"
                            id="btn-submit"
                            class="btn btn-secondary btn-round"
                            @click="onSubmitClicked()">
                        Add Brand
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts" setup>
    import ImpersonateBanner from '@/components/ImpersonateBanner.vue'
    import UserFooter from '@/components/UserFooter.vue'
    import UserMenu from '@/components/UserMenu.vue'
    import UserProfileDropdown from '@/components/UserProfileDropdown.vue'

    import { computed, onMounted, ref } from 'vue'

    import axios, { AxiosError } from 'axios'
    import { CustomRegex } from '@/assets/CustomRegex'
    import { MbscSnackbar } from '@/assets/mobiscroll/js/mobiscroll.vue.min.js'
    import { BrandDto } from '@/assets/types'

    const isLoading = ref(false)
    const isSnackbarOpen = ref(false)
    const snackbarMessage = ref('')

    const emptyBrand: Readonly<BrandDto> = {
        id: null,
        companyId: null,
        brandName: '',
        companyName: null,
        approval: null,
        approvalNote: '',
        isActive: true
    }

    const addBrand = ref<BrandDto>(JSON.parse(JSON.stringify(emptyBrand)))
    const approvalOptions = ref(['Any', 'Approved', 'Pending', 'Denied'])
    const closeModalButton = ref<HTMLButtonElement>()
    const brandDataDto = ref([])
    const brandNameBlured = ref(false)
    const searchValue = ref('')
    const searchValueFilter = ref('')
    const selectedApproval = ref('Any')
    const selectedStatus = ref('Active')
    const statusOptions = ref(['All', 'Active', 'Deactivated'])

    onMounted(async () => {
        await loadData()
    })

    const loadData = (async () => {
        try {
            brandDataDto.value = (await axios.get('/api/brand/company')).data
        } catch {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const onSubmitClicked = (async () => {
        try {
            if (!validate()) {
                return
            }

            await axios.post('/api/brand', addBrand.value)
            showSnackbar('Brand has been submitted for admin approval.')
            closeModalButton.value?.click()
            await loadData()
            brandNameBlured.value = false
            addBrand.value = JSON.parse(JSON.stringify(emptyBrand))
        } catch (error) {
            const axiosError = error as AxiosError
            if (axiosError.response?.status == 400) {
                showSnackbar('' + axiosError.response?.data)
            } else {
                showSnackbar('An error occurred. Please contact support.')
            }
        }
    })

    const validate = (() => {
        brandNameBlured.value = true
        return validBrandName()
    })

    const validBrandName = (() => {
        let regex = CustomRegex.isBetweenOneAndOneHundredChars
        if (regex.test(addBrand.value.brandName.toLowerCase())) {
            return true
        }
    })

    const onSearchClicked = (() => {
        searchValueFilter.value = searchValue.value
    })

    const filteredBrands = computed(() => {
        let result = brandDataDto.value

        if (searchValueFilter.value !== '' && result.length > 0) {
            result = result.filter((brand) =>
                brand.brandName.toLowerCase().includes(searchValueFilter.value.toLowerCase())
            )
        }

        if (result && selectedApproval.value !== 'Any') {
            result = result.filter((brand) => brand.approval === selectedApproval.value)
        }

        if (result && selectedStatus.value === 'Active') {
            result = result.filter((brand) => brand.isActive === true)
        } else if (result && selectedStatus.value === 'Deactivated') {
            result = result.filter((brand) => brand.isActive === false)
        }

        return result
    })

    const onChangeStatusClicked = (async (id: number, isActive: boolean) => {
        try {
            const brandDto: BrandDto = {
                id: id,
                companyId: null,
                brandName: '',
                companyName: '',
                approval: '',
                approvalNote: '',
                isActive: !isActive
            }
            await axios.put('/api/brand/is-active', brandDto)
            await loadData()
        } catch {
            showSnackbar('An error occurred. Please contact support.')
        }
    })

    const showSnackbar = ((msg: string) => {
        snackbarMessage.value = msg
        isSnackbarOpen.value = true
    })

</script>