<template>

    <div v-if="userProfile && userProfile.isImpersonating" class="impersonate-banner">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col">
                    <label class="small mb-1"><i class="far fa-theater-masks"></i> Impersonating</label>
                    <h5 class="mb-0 text-600">{{ userProfile.firstName + ' ' + userProfile.lastName }}</h5>
                    <h6 class="mb-0">{{ userProfile.companyName }}</h6>
                </div>
                <div class="col-auto">
                    <button type="button" 
                            class="btn btn-secondary btn-round hvr-grow-sm btn-sm px-4"
                            @click.prevent="onImpersonateEndClicked()">End</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts" setup>
    import axios from 'axios'
    import { UserProfileDto } from '@/assets/types'
    import { onMounted, ref } from 'vue'
    import { useCookie } from 'vue-cookie-next'
    import { useRouter } from 'vue-router'

    const emptyUserProfile: Readonly<UserProfileDto> = {
        userId: null,
        firstName: ' ',
        lastName: ' ',
        phone: '',
        email: '',
        companyName: '',
        isImpersonating: false
    }

    const cookies = useCookie()
    const router = useRouter()
    const userProfile = ref<UserProfileDto>(JSON.parse(JSON.stringify(emptyUserProfile)))
    const isSnackbarOpen = ref(false)
    const snackbarMessage = ref('')

    onMounted(async () => {
        try {
            userProfile.value = (await axios.get('/api/user/profile')).data
        } catch (error) {
            showSnackbar('Failed to retrieve user profile. Please contact support.')
        }
    })

    const onImpersonateEndClicked = (async () => {
        await axios.post(`/api/user/stop-impersonation`)
        cookies.setCookie('role:SuperAdmin', 'SuperAdmin')
        router.push({ name: 'login' })
    })

    const showSnackbar = ((msg: string) => {
        snackbarMessage.value = msg
        isSnackbarOpen.value = true
    })

</script>
